import { Link } from 'gatsby'
import React, { useState, useRef } from 'react'
import { useOutsideClick, toggleNav } from '../Layout/HeaderUtils'
import { useUserContext } from '../../contexts/User/userContext'

import GoodpathLogo from '../../images/logos/goodpath-logo.svg'
import ConditionProvider from '../common/ConditionProvider'
import { links } from '../../utils/useAllowedConditions'

const mobileLink =
  'flex items-center p-3 -m-3 rounded-lg hover:bg-gray-50 border leading-tight'

const secondaryMobileLink =
  'text-base font-bold text-gp-grey-100 hover:text-gp-grey-100 py-4'

const desktopLink =
  'text-base xl:text-l font-bold text-gp-grey-80 hover:text-gp-grey-100 hover:no-underline'

const HeaderNavComponent = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const [dropdownClickedOutside, setDropdownClickedOutside] = useState(false)
  const [mobileMenuClickedOutside, setMobileMenuClickedOutside] = useState(
    false
  )

  // Dropdown on desktop
  const dropdownWrapperRef = useRef(null)
  useOutsideClick(dropdownWrapperRef, dropdownOpen, (outsideClicked) => {
    if (outsideClicked && dropdownOpen) {
      setDropdownOpen(false)
    }
    setDropdownClickedOutside(outsideClicked)
  })

  // Mobile menu
  const mobileMenuWrapperRef = useRef(null)
  useOutsideClick(mobileMenuWrapperRef, mobileMenuOpen, (outsideClicked) => {
    if (outsideClicked && mobileMenuOpen) {
      setMobileMenuOpen(false)
    }
    setMobileMenuClickedOutside(outsideClicked)
  })

  function mobileMenuClickOutside() {
    if (!mobileMenuClickedOutside) {
      setMobileMenuOpen(!mobileMenuOpen)
    }
  }

  function loginLink() {
    toggleNav.bind(null, setNavVisible, navVisible)
  }

  // Pages to exclude login from the navigation
  const { page } = props
  const { user, logout } = useUserContext()

  const pagesToExcludeLogin = ['employer', 'partner', 'login']
  const hideLogin = pagesToExcludeLogin.some((currentPage) => {
    return page.replace(/\/+$/, '').startsWith(currentPage)
  })

  // Logout user
  const [navVisible, setNavVisible] = useState(false)

  const logoutUser = () => {
    logout('/')
    toggleNav(setNavVisible, navVisible)
  }

  const conditions = links

  // noinspection HtmlUnknownAnchorTarget
  return (
    <>
      <a
        href="#main"
        className="inline-flex text-2xl font-bold transition-all duration-300 ease-in-out border outline-none sr-only focus:not-sr-only bg-gp-white text-gp-violet border-gp-violet"
      >
        Skip to main content
      </a>

      <header className="sticky top-0 z-40 w-full bg-white shadow-md">
        <div className="relative bg-white shadow-md">
          <div className="flex items-center justify-between px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:justify-start lg:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <Link to="https://www.goodpath.com">
                <span className="sr-only">Goodpath</span>
                <img
                  className="w-auto h-8 sm:h-10 hover:opacity-75 focus:opacity-75"
                  src={GoodpathLogo}
                  alt="Goodpath logo."
                />
              </Link>
            </div>
            <div className="-my-2 -mr-2 lg:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 bg-white text-gp-grey-80 hover:text-gp-grey-100 hover:bg-gp-grey-5 focus:outline-violet"
                onClick={() =>
                  setMobileMenuOpen((mobileMenuOpen) => !mobileMenuOpen)
                }
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="w-8 h-8"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="items-center hidden space-x-10 lg:flex">
              <div className="relative">
                <button
                  type="button"
                  className="inline-flex items-center text-base font-bold bg-white text-gp-grey-80 group hover:text-gp-grey-100 focus:outline-violet"
                  aria-expanded="false"
                  onClick={() =>
                    !dropdownClickedOutside &&
                    setDropdownOpen((dropdownOpen) => !dropdownOpen)
                  }
                >
                  <span className={desktopLink}>Conditions we treat</span>

                  <svg
                    className={`w-6 h-6 mt-1 ml-2 text-gp-grey-80 group-hover:text-gp-grey-80  ${
                      dropdownOpen ? 'transform rotate-180' : ''
                    }`}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Desktop dropdown */}
                {dropdownOpen && (
                  <div
                    className="absolute z-10 w-screen max-w-md mt-3 -ml-4 transform lg:max-w-2xl lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                    ref={dropdownWrapperRef}
                  >
                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                      <div className="relative grid gap-6 px-5 py-6 bg-white sm:gap-8 sm:p-8 lg:grid-cols-2">
                        {conditions.map((desktop) => (
                          <Link
                            key={desktop.url}
                            to={desktop.url}
                            className="flex items-center p-3 -m-3 transition-all duration-300 ease-in-out rounded-lg hover:bg-gp-honey-30 focus:bg-gp-honey-30 hover:no-underline focus:no-underline"
                            onClick={() => {
                              if (!dropdownClickedOutside) {
                                setDropdownOpen(!dropdownOpen)
                              }
                            }}
                          >
                            <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-gp-violet sm:h-12 sm:w-12">
                              <img
                                src={desktop.icon}
                                alt={desktop.alt}
                                className="w-8 h-8"
                              />
                            </div>
                            <div className="ml-4">
                              <p className="text-xl font-bold text-gp-grey-100">
                                {desktop.title}
                              </p>
                            </div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <Link to="https://www.goodpath.com/learn" className={desktopLink}>
                {' '}
                Learn{' '}
              </Link>
              <Link to="/employer" className={desktopLink}>
                {' '}
                For employers{' '}
              </Link>
              <Link
                to="https://www.goodpath.com/health-partner"
                className={desktopLink}
              >
                {' '}
                Health Partners{' '}
              </Link>
            </nav>

            <div className="items-center justify-end hidden lg:flex lg:flex-1 lg:w-0">
              {!hideLogin &&
                (user.isAuth ? (
                  <Link
                    to="/"
                    link={{
                      to: '/',
                      isInternal: true
                    }}
                    onClick={logoutUser}
                    className="text-base font-bold text-gp-grey-80 whitespace-nowrap hover:text-gp-grey-100"
                  >
                    Logout
                  </Link>
                ) : (
                  <Link
                    to="/login"
                    link={{
                      url: '/login',
                      title: 'Login',
                      isInternal: true
                    }}
                    onClick={toggleNav.bind(null, setNavVisible, navVisible)}
                    className="text-base font-bold text-gp-grey-80 whitespace-nowrap hover:text-gp-grey-100"
                  >
                    Login
                  </Link>
                ))}
              <Link
                id="nav-start1"
                to="/partner"
                className="inline-flex items-center justify-center px-4 py-3 ml-8 text-base font-bold text-white transition-all duration-300 ease-in-out border border-transparent rounded-md bg-gp-violet hover:bg-gp-violet-120 hover:no-underline focus:bg-gp-violet-120 whitespace-nowrap "
              >
                {' '}
                Request a Demo{' '}
              </Link>
            </div>
          </div>

          {mobileMenuOpen && (
            <div
              className="absolute inset-x-0 top-0 z-30 p-2 transition origin-top-right transform lg:hidden"
              ref={mobileMenuWrapperRef}
            >
              <div className="bg-white divide-y-2 rounded-lg shadow-lg divide-gp-grey-60">
                <div className="px-5 pt-5 pb-6">
                  <div className="flex items-center justify-between">
                    <div>
                      <Link to="/" className="flex py-4">
                        <span className="sr-only">Goodpath</span>
                        <img
                          className="w-auto h-8"
                          src={GoodpathLogo}
                          alt="Goodpath logo."
                        />
                      </Link>
                    </div>
                    <div className="-mr-2">
                      <button
                        type="button"
                        className="inline-flex items-center justify-center p-2 bg-white rounded-md text-gp-grey-80 hover:text-gp-grey-100 hover:bg-gp-grey-5 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gp-violet"
                        onClick={() =>
                          setMobileMenuOpen((mobileMenuOpen) => !mobileMenuOpen)
                        }
                      >
                        <span className="sr-only">Close menu</span>

                        <svg
                          className="w-8 h-8"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid grid-cols-2 gap-8">
                      {conditions.map((mobile) => (
                        <Link
                          to={mobile.url}
                          key={mobile.url}
                          className={mobileLink}
                          onClick={() => {
                            mobileMenuClickOutside()
                          }}
                        >
                          <div className="flex items-center justify-center flex-shrink-0 w-10 h-10 text-white rounded-md bg-gp-violet">
                            <img
                              src={mobile.icon}
                              alt={mobile.alt}
                              className="w-8 h-8"
                            />
                          </div>
                          <div className="ml-4 text-base font-bold text-gp-grey-100">
                            {mobile.title}
                          </div>
                        </Link>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="px-5 py-6">
                  <div className="grid grid-cols-2 gap-4">
                    <Link
                      to="/learn"
                      className={secondaryMobileLink}
                      onClick={() => {
                        mobileMenuClickOutside()
                      }}
                    >
                      {' '}
                      Learn{' '}
                    </Link>
                    <Link
                      to="/employer"
                      className={secondaryMobileLink}
                      onClick={() => {
                        mobileMenuClickOutside()
                      }}
                    >
                      {' '}
                      For employers{' '}
                    </Link>
                    <Link
                      to="https://www.goodpath.com/health-partner"
                      className={secondaryMobileLink}
                      onClick={() => {
                        mobileMenuClickOutside()
                      }}
                    >
                      {' '}
                      Health Partners{' '}
                    </Link>
                  </div>
                  {/* Mobile button */}
                  <div className="mt-6">
                    <Link
                      id="nav-start2"
                      to="/partner"
                      className="flex items-center justify-center w-full px-4 py-4 text-base font-bold text-white border border-transparent rounded-md bg-gp-violet hover:bg-gp-violet-120 hover:no-underline focus:bg-gp-violet-120"
                      onClick={() => {
                        mobileMenuClickOutside()
                      }}
                    >
                      {' '}
                      Request a Demo{' '}
                    </Link>
                    {!hideLogin &&
                      (user.isAuth ? (
                        <p className="py-4 text-base font-bold text-center text-gp-grey-80">
                          <Link
                            to="/"
                            link={{
                              to: '/',
                              isInternal: true
                            }}
                            onClick={() => {
                              logoutUser()
                              mobileMenuClickOutside()
                            }}
                            className="flex items-center justify-center py-4"
                          >
                            Logout
                          </Link>
                        </p>
                      ) : (
                        <p className="py-4 mt-6 text-base font-bold text-center text-gp-grey-80">
                          Existing customer?{' '}
                          <Link
                            to="/login"
                            link={{
                              url: '/login',
                              title: 'Login',
                              isInternal: true
                            }}
                            onClick={() => {
                              loginLink()
                              mobileMenuClickOutside()
                            }}
                            className="py-4 text-gp-grey-100"
                          >
                            Login
                          </Link>
                        </p>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  )
}

const HeaderNav = (props) => (
  <ConditionProvider>
    <HeaderNavComponent {...props} />
  </ConditionProvider>
)

export default HeaderNav
