import React from 'react'

import SimpleHeader from '../components/Layout/SimpleHeader';

export default function SimpleLayout({ children }) {
  return (
    <div>
      <SimpleHeader
        backButton={false}
      />
      <div>
        <main>{children}</main>
      </div>
    </div>
  )
}
