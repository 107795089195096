import axios from 'axios'
import { logErr } from '../utils/logger'
import { addAuthHeader } from 'goodpath-common'
import commonApi, { addRequestIdMiddleware } from './dashboard.api'

const program = {
  updateProgram: async function (program, auth) {
    const { authType } = auth
    try {
      const { data } = await axios.put(
        '/netlify/api/program',
        {
          userProgram: program
        },
        await addAuthHeader({ authType })
      )
      return data
    } catch (e) {
      throw new Error(e.response.data?.message)
    }
  },
  approve: async function (program, auth) {
    const { authType } = auth
    try {
      const { data } = await axios.post(
        '/netlify/api/program/approve',
        {
          userProgram: program
        },
        await addAuthHeader({ authType })
      )
      return data
    } catch (e) {
      throw new Error(e.response.data?.message)
    }
  },
  getMiniPrograms: async function ({
    responses,
    conditionSlug,
    cancelToken,
    dynamicRules
  }) {
    const { data } = await axios.post(
      '/netlify/api/program/getMiniProgramsForDashboard',
      {
        responses,
        conditionSlug,
        dynamicRules
      },
      await addAuthHeader({ axiosConfig: { cancelToken } })
    )
    return data
  },
  createForEnrolledUser: async function (params, authType) {
    const res = await axios.post(
      `/netlify/api/program/createForEnrolledUser`,
      params,
      await addAuthHeader({ authType })
    )
    console.log('Create program for enrolled user', {
      params,
      response: res.data
    })

    return res
  },
  terminateProgram: async function (programId) {
    const { data } = await axios.post(
      `/netlify/api/program/terminate`,
      { programId },
      await addAuthHeader()
    )
    return data
  }
}

const user = {
  postUser: async function (user) {
    return await axios.post('/netlify/api/user', user)
  },
  patchUser: async function (id, data, params) {
    return await axios.patch(`/netlify/api/user/${id}`, data, { params })
  },
  register: async function (user) {
    return await axios.post('/api/user/register', user)
  },
  checkUserAuth: ({ email, userId }) => {
    return axios
      .post('/netlify/api/user/hasAuth', { email, userId })
      .then((response) => {
        console.log('api.user.hasAuth called', response.data)
        return response
      })
      .catch((err) => {
        logErr({ err, msg: `Error setting user hasAuth` })
        throw err
      })
  }
}

const employer = {
  eligible: async (props) => {
    try {
      const resp = await axios.post('/netlify/api/employer/eligible', props)
      if (resp.status === 200 && resp.data) {
        return resp.data
      }
    } catch (err) {
      logErr({
        err,
        msg: 'Error enrolling user into employer program.',
        ...props
      })
      throw err
    }
  },
  get: async (queryParams) => {
    try {
      console.log('calling employer')
      const resp = await axios.get('/netlify/api/employer', {
        axiosConfig: {
          headers: { 'cache-control': 'no-cache,max-age=0', pragma: 'no-cache' }
        },
        params: queryParams
      })
      if (resp.status === 200 && resp.data) {
        return resp.data
      }
    } catch (err) {
      logErr({
        err,
        queryParams,
        msg: 'Error fetching employer.'
      })
      throw err
    }
  }
}

const auth = {
  admin: {
    async authenticate({ provider, params = {} }) {
      const resp = await axios.post(
        `/netlify/api/auth/${provider}/authenticate`,
        params
      )
      return resp.data
    },
    async refresh({ provider, params = { refreshToken: '' } }) {
      const resp = await axios.post(
        `/netlify/api/auth/${provider}/refresh`,
        params
      )
      return resp.data
    }
  }
}

const allExports = {
  assessment: commonApi.assessment || {},
  auth,
  content: commonApi.content || {},
  eligibility: commonApi.eligibility || {},
  employer: { ...employer, ...(commonApi.employer || {}) },
  inventory: commonApi.inventory || {},
  order: commonApi.order || {},
  program: { ...program, ...(commonApi.program || {}) },
  pushNotifications: { ...(commonApi.pushNotifications || {}) },
  user: { ...user, ...(commonApi.user || {}) }
}

axios.interceptors.request.use(addRequestIdMiddleware)

export default allExports
