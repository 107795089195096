import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useReducer,
  useState
} from 'react'
import { useLocation, Location } from '@reach/router'

import { graphql, useStaticQuery } from 'gatsby'
import { useCookies } from 'react-cookie'
import * as actions from './employerActions'
import { useUserContext } from '../User/userContext'
import { Country } from '../../components/common/CountryProvider'
import { FluidObject } from 'gatsby-image'

const defaultState = ({
  slug: '',
  employer: {},
  countries: []
} as unknown) as EmployerContextType

const cookieDomain = process.env.GATSBY_COOKIE_DOMAIN

const EmployerContext = createContext<EmployerContextType>(defaultState)

const reducer = (
  state: EmployerContextType,
  action: Action
): EmployerContextType => {
  switch (action.type) {
    case actions.SET_EMPLOYER: {
      return {
        ...state,
        employer: action?.payload?.employer,
        countries: action?.payload?.employer?.countries || []
      }
    }
    case actions.SET_EMPLOYER_SLUG: {
      return {
        ...state,
        slug: action?.payload?.slug!
      }
    }
    case actions.RESET: {
      return {
        ...state,
        ...defaultState
      }
    }

    default:
      return state
  }
}

const EmployerProviderComponent = ({
  initialState = defaultState,
  children
}: Provider) => {
  const employerData = useStaticQuery(graphql`
    query EmployerProviderQuery {
      allContentfulEmployer(filter: { node_locale: { eq: "en-US" } }) {
        edges {
          node {
            companyName
            companyPaymentBlurb {
              internal {
                content
              }
            }
            eligibleConditions {
              slug
            }
            paymentType
            hideEmployerWording
            slug
            countries {
              countryCode
              countryName
            }
            showEnrollmentTypeSelector
            showDivisions
            divisions {
              label
              value
            }
            eligibilityCheckType
            tier
            autoRespondedQuestions {
              question {
                questionId: contentfulid
              }
              responses {
                responseId: contentfulid
              }
            }
            headerLogo {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)

  const [state, dispatch] = useReducer<
    React.Reducer<EmployerContextType, Action>
  >(reducer, initialState)

  const [cookies, setCookie, removeCookie] = useCookies([
    'employer' // Associate the current session with a given employer.
  ])

  const employerCookieValue = useMemo(() => cookies?.employer, [cookies])

  const { user } = useUserContext() as any
  const location = useLocation()

  const [initCheck, setInitCheck] = useState({
    cookie: false,
    userRecord: false,
    urlParam: false
  })

  const getEmployerBySlug = useCallback(
    (slug?: string) =>
      employerData?.allContentfulEmployer?.edges.find(
        (e: { node: { slug: string } }) => e?.node.slug === slug
      )?.node,
    [employerData?.allContentfulEmployer?.edges]
  )

  const setEmployerSlug = useCallback((slug: string) => {
    if (!slug) return

    dispatch({
      type: actions.SET_EMPLOYER_SLUG,
      payload: { slug }
    })
  }, [])

  const resetEmployerState = useCallback(() => {
    removeCookie('employer', {
      maxAge: 0,
      path: '/',
      ...(cookieDomain ? { domain: cookieDomain } : {})
    })
    dispatch({
      type: actions.RESET,
      payload: {} as EmployerContextType
    })
  }, [removeCookie])

  // Set cookie and state
  useEffect(() => {
    const employer = getEmployerBySlug(state.slug)
    if (!employer) return

    dispatch({
      type: actions.SET_EMPLOYER,
      payload: {
        employer
      }
    })

    setCookie(
      'employer',
      JSON.stringify({
        e: state.slug,
        ec: employer?.eligibleConditions?.map((c: any) => c.slug) || []
      }),
      {
        maxAge: 60 * 60 * 24 * 30, // 30 days
        path: '/',
        ...(cookieDomain ? { domain: cookieDomain } : {})
      }
    )
  }, [setCookie, state.slug, getEmployerBySlug])

  // Set state by cookie
  // Only set state by cookie if no state is set
  useEffect(() => {
    const cookieEmployerSlug = employerCookieValue?.e

    if (cookieEmployerSlug && !state.slug) {
      setEmployerSlug(cookieEmployerSlug)
    }
    setInitCheck((prev) => ({ ...prev, cookie: true }))
  }, [employerCookieValue?.e, setEmployerSlug, state.slug])

  // Set state by user record
  useEffect(() => {
    if (user?.employerSlug && state.slug !== user.employerSlug) {
      setEmployerSlug(user.employerSlug)
    } else if (user?.isAuth && !user?.employerSlug && state.slug) {
      resetEmployerState()
    }
    setInitCheck((prev) => ({ ...prev, userRecord: true }))
  }, [
    resetEmployerState,
    setEmployerSlug,
    state.slug,
    user.employerSlug,
    user?.isAuth
  ])

  // Set state by URL param
  useEffect(() => {
    const params = new URL(location.href).searchParams
    const employerUrlParam = params.get('e')

    if (employerUrlParam && state.slug !== employerUrlParam) {
      dispatch({
        type: actions.SET_EMPLOYER_SLUG,
        payload: { slug: employerUrlParam }
      })
    }
    setInitCheck((prev) => ({ ...prev, urlParam: true }))
  }, [state.slug, location?.href])

  const employers = useMemo(
    () => employerData?.allContentfulEmployer?.edges?.map((e: any) => e?.node),
    [employerData?.allContentfulEmployer?.edges]
  )

  const initialized = useMemo(() => !Object.values(initCheck).some((v) => !v), [
    initCheck
  ])

  const contextValue = useMemo(
    () => ({
      ...state,
      initialized,
      employers,
      setEmployerSlug,
      resetEmployerState,
      getEmployerBySlug
    }),
    [
      state,
      employers,
      setEmployerSlug,
      resetEmployerState,
      getEmployerBySlug,
      initialized
    ]
  )

  return (
    <EmployerContext.Provider value={contextValue}>
      {children}
    </EmployerContext.Provider>
  )
}

export const useEmployerContext = () => useContext(EmployerContext)

export const EmployerProvider = (props: Provider) => {
  return <Location>{() => <EmployerProviderComponent {...props} />}</Location>
}

type EmployerContextType = {
  initialized: boolean
  slug: string
  employer?: EmployerType
  employers?: EmployerType[]
  countries?: Country[]
  cookieForEmployer?: any
  getEmployerBySlug: (slug?: string) => EmployerType | undefined
}

type EligibleConditionsType = {
  slug: string
}

export type EmployerType = {
  companyName: string
  companyPaymentBlurb: {
    internal: {
      content: string
    }
  }
  eligibleConditions: EligibleConditionsType[]
  hideEmployerWording: boolean
  showEnrollmentTypeSelector: boolean
  showDivisions: boolean
  countries: Country[]
  paymentType: string
  divisions: {
    label: string
    value: string
  }
  eligibilityCheckType: 'No Check' | 'Work email'
  slug: string
  tier?: EmployerTier
  autoRespondedQuestions?: {
    question: {
      questionId: string
    }
    responses: {
      responseId: string
    }[]
  }[]
  headerLogo?: {
    fluid: FluidObject
  }
}

type Provider = {
  initialState?: EmployerContextType
  children: React.ReactNode
}

type Action = {
  type: 'SET_EMPLOYER_SLUG' | 'RESET' | 'SET_EMPLOYER'
  payload: Partial<EmployerContextType>
}

export enum EmployerTier {
  Full = 'Full',
  GPDirect = 'GPDirect'
}
