import LogRocket from 'logrocket'
import { levels, lowestPriorityLevel } from '../../common/logging/Constants'
import { ConsoleTransport } from '../../common/logging/transports/ConsoleTransport'
import { LogRocketTransport } from '../logging/transports/LogRocketTransport'
import { Logger } from '../../common/logging/Logger'
import { redirectConsole } from '../../common/logging/ConsoleRedirection'
import { isDevelopment } from '../../common/utils/environmentUtils'

// Logging parameters
const DEFAULT_LOG_NAME = 'global'
const DEFAULT_LOG_LEVEL = process.env.GATSBY_DEFAULT_LOG_LEVEL || levels.info
const DEFAULT_LOG_FORMAT =
  process.env.GATSBY_DEFAULT_LOG_FORMAT ||
  '%s-timestamp [%s-name] %s-level: %s-message'
const USER_LOG_NAME = 'user'
const USER_LOG_LEVEL = process.env.GATSBY_USER_LOG_LEVEL || levels.info
const USER_LOG_FORMAT =
  process.env.GATSBY_USER_LOG_FORMAT ||
  '%s-timestamp [%s-name,%s-userId] %s-level: %s-message'

/**
 * Log an error to our error reporting services.
 *
 * @param err - The error object to log (if there is one).
 * @param msg - An error message. This be logged as extra data on the event and
 * will be used as the message for the exception if no err parameter is passed.
 * @param tags - Tags to add to the event
 * @param extra - Any extra JSON data to provide on the event
 */
export const logErr = ({ err, msg, tags = {}, extra = {} }) => {
  const error = logToErrorReporting({ level: 'error', err, msg, tags, extra })

  console.error(msg, tags, extra, error)

  return error
}

/**
 * Log a warning to our error reporting services.
 *
 * @param err - The error object to log (if there is one).
 * @param msg - An error message. This be logged as extra data on the event and
 * will be used as the message for the exception if no err parameter is passed.
 * @param tags - Tags to add to the event
 * @param extra - Any extra JSON data to provide on the event
 */
export const logWarn = ({ err, msg, tags = {}, extra = {} }) => {
  const error = logToErrorReporting({ level: 'warning', err, msg, tags, extra })

  console.warn(msg, tags, extra, error)

  return error
}

/**
 * @param level - 'error' or 'warning'
 * @param err - The error object to log (if there is one).
 * @param msg - An error message. This be logged as extra data on the event and
 * will be used as the message for the exception if no err parameter is passed.
 * @param tags - Tags to add to the event
 * @param extra - Any extra JSON data to provide on the event
 */
const logToErrorReporting = ({ level, err, msg, tags = {}, extra = {} }) => {
  const realJsError = err instanceof Error ? err : null
  const error = realJsError || new Error(msg)

  if (typeof window !== `undefined`) {
    try {
      LogRocket.captureException(error, {
        tags: { ...tags, level },
        extra
      })

      if (window.Sentry) {
        // NOTE: Adding the message into the extra data as well in case it's long.
        //   Sentry will truncate it if it's long (since it's used as the error title).
        // eslint-disable-next-line no-undef
        Sentry.captureException(error, {
          tags,
          extra: { ...extra, realJsError, err, msg },
          level
        })
      }
    } catch (e) {
      console.error('Exception while reporting error', e)
    }
  }

  return error
}

/**
 * Configures a default logger, a current global logger (in window.logger) and redirects the console to the current
 * global logger. This also sets up getter/setters for window.logger so that when it is changed the console is
 * redirected to the updated global logger.
 *
 * NOTE: If you're using a logger just for a module, don't re-assign window.logger. Just make a child logger from
 * window.logger and use that in the module.
 */
export function configureLogger() {
  if (typeof window !== 'undefined') {
    window.defaultLogger = createWindowLogger({
      name: DEFAULT_LOG_NAME,
      level: DEFAULT_LOG_LEVEL,
      format: DEFAULT_LOG_FORMAT
    })

    // Define a getter and setter for the logger so we can have it redirect the console when changed.
    Object.defineProperties(window, {
      logger: {
        get: function() {
          return this._logger
        },
        set: function(logger) {
          this._logger = logger

          // Only redirect the console locally if we explicity ask to in your environment.
          if (process.env.GATSBY_REDIRECT_CONSOLE || !isDevelopment) {
            redirectConsole({ logger })
          }
        }
      }
    })

    // Make the current logger the default logger...
    window.logger = window.defaultLogger
  }
}

/**
 * Sets the current window.logger to a new "user" logger that includes user information in the logs.
 * @param userId
 */
export function setUserLogger({ user }) {
  if (
    typeof window !== 'undefined' &&
    window.defaultLogger &&
    user?.userId &&
    user.userId !== window?.logger?.meta?.userId
  ) {
    // Set the user logger (if not already set)
    window.logger = createWindowLogger({
      name: USER_LOG_NAME,
      level: USER_LOG_LEVEL,
      format: USER_LOG_FORMAT,
      meta: { userId: user.userId }
    })
  } else if (!user?.userId) {
    // Clear the user logger
    window.logger = window.defaultLogger
  }
}

// Helper that creates a logger to the console and loggly with the given format, level, and metadata...
function createWindowLogger({ name, format, level, meta = {} }) {
  // Don't include outputting to the console if we're stripping the console...
  const consoleTransport = process.env.GATSBY_STRIP_CONSOLE
    ? []
    : [new ConsoleTransport({ level: lowestPriorityLevel, format, meta })]
  const transports = [
    ...consoleTransport,
    new LogRocketTransport({ level: lowestPriorityLevel, format, meta })
  ]
  const logger = new Logger({ name, level, transports })
  return logger
}
