import React from 'react'
import Img, { FluidObject } from 'gatsby-image'

export default function EmployerLogo({ image }: Props) {
  return (
    <div>
      <Img style={style} fluid={image} alt="company-logo" />
    </div>
  )
}

const style = {
  height: 'fit-content',
  width: '16rem'
}

type Props = {
  image: FluidObject
}
