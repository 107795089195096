import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import Logo from '../../../assets/logo.svg'

export default function HeaderLogo({
  to = '/',
  colored = true,
  clickable = true
}) {
  const linkClass = classNames(
    `fill-current ${
      clickable
        ? 'hover:text-gp-violet-70 focus:text-gp-violet-70 active:text-gp-violet'
        : ''
    } w-full max-w-150 block`,
    { 'text-gp-violet': colored, 'text-white': !colored }
  )
  const LogoComponent = () => (
    <Logo className={`w-full ${clickable ? 'cursor-pointer' : ''} lg:px-0`} />
  )
  return clickable ? (
    <Link to={to} className={linkClass}>
      <LogoComponent />
    </Link>
  ) : (
    <div className={linkClass}>
      <LogoComponent />
    </div>
  )
}
