import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
  useState,
  useRef
} from 'react'
import {
  retrieveFromLocalStorageAndTransform,
  putInLocalStorage,
  jsonTransform,
  removeFromLocalStorageArray
} from '../../utils/useLocalStorage'
import {
  USER,
  QUIZ_STATE,
  PLAN_ID,
  CONDITION_SLUG,
  CONDITION_NAME,
  PLAN_PRICE,
  SELECTED_PLAN,
  VIEWED_PLAN,
  GOODPATH_SCORE,
  currentActiveQuizKey
} from '../../constants/localStorage'
import * as actions from './userActions'
import { auth } from '../../utils/firebase'
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  signOut,
  onAuthStateChanged,
  updatePassword
} from 'firebase/auth'
import { navigate } from 'gatsby'
import { setUserAttributes } from '../../utils/analytics-tracking'
import { getInstanceUrl } from '../../../common/utils/environmentUtils'
import api from '../../api'
import useReducerWithSideEffects, {
  Update,
  NoUpdate,
  UpdateWithSideEffect
} from 'use-reducer-with-side-effects'
import { setUserLogger } from '../../utils/logger'
import { resetTokenCaller, setTokenCaller } from 'goodpath-common'
import { useLocalizationContext } from '../Localization/localizationProvider'

const UserContext = createContext(null)
export const UserConsumer = UserContext.Consumer

const emptyUser = {
  firstName: null,
  lastName: null,
  email: null,
  workEmail: null,
  employeeId: null,
  userId: null,
  conditionSlug: null,
  employerSlug: null,
  subscriptionId: null,
  isAuth: undefined,
  state: undefined,
  createdAt: null,
  settings: null,
  address: null,
  phoneNumber: null,
  enrolled: null
}

export const defaultState = {
  user: retrieveFromLocalStorageAndTransform(USER, emptyUser, jsonTransform),
  userInitialized: false,
  authUser: null
}

setUserAttributes(defaultState.user)

function reducer(state, action) {
  const { payload } = action
  switch (action.type) {
    case actions.PROCEED_TO_NAVIGATION:
      return Update({
        ...state,
        proceedToNavigation: payload
      })
    case actions.UPDATE_USER: {
      return Update({
        ...state,
        user: { ...state.user, ...payload }
      })
    }
    case actions.SET_AUTH_USER: {
      return UpdateWithSideEffect(
        {
          ...state,
          user: {
            ...state.user,
            userId: payload?.authUser?.uid || state.user.userId,
            email: payload?.authUser?.email || state.user.email,
            isAuth: payload?.authUser != null,
            ...payload.user
          },
          authUser: payload.authUser,
          userInitialized: true
        },
        [(state) => setUserLogger({ user: state?.user })]
      )
    }
    case actions.LOGOUT: {
      return Update({
        ...state,
        user: emptyUser,
        authUser: null,
        userInitialized: false
      })
    }
    case actions.SET_USER_STATE: {
      return Update({
        ...state,
        user: { ...state.user, state: payload }
      })
    }
    default:
      return NoUpdate()
  }
}

function clearLoggedInUserValues() {
  // clear localStorage
  removeFromLocalStorageArray([
    USER,
    QUIZ_STATE,
    GOODPATH_SCORE,
    currentActiveQuizKey,
    'quizResponsesForServer',
    'currentQuestionIds',
    PLAN_ID,
    CONDITION_SLUG,
    CONDITION_NAME,
    PLAN_PRICE,
    SELECTED_PLAN,
    VIEWED_PLAN,
  ])
}

export function UserProvider({ initialState = {}, children }) {
  const [state, dispatch] = useReducerWithSideEffects(reducer, {
    ...defaultState,
    ...initialState
  })
  const preLogoutCallbacks = useRef(new Set())
  const postLogoutCallbacks = useRef(new Set())
  const preLoginCallbacks = useRef(new Set())
  const postLoginCallbacks = useRef(new Set())

  const [navigationUrl, setNavigationUrl] = useState('')
  const { locale } = useLocalizationContext()

  const getUserState = useCallback(async () => {
    if (!state.authUser?.uid) return null
    const userState = await api.user.getStateById({
      userId: state.authUser.uid
    })
    return userState
  }, [state.authUser?.uid])

  const refreshUserState = useCallback(async (tempState) => {
    if (tempState) {
      dispatch({
        type: actions.SET_USER_STATE,
        payload: tempState
      })
    }

    if (!state.authUser?.uid) return null
    const userState = await api.user.getStateById({
      userId: state.authUser.uid
    })
    dispatch({
      type: actions.SET_USER_STATE,
      payload: userState
    })
    return userState
  }, [state.authUser?.uid, dispatch])

  const updateUser = useCallback(
    (userData = {}) => {
      console.log(`userContext updateUser userData`, userData)
      setUserAttributes({
        userId: state?.user?.userId,
        email: state?.user?.email,
        ...userData,
      })
      dispatch({
        type: actions.UPDATE_USER,
        payload: userData
      })
    },
    [dispatch, state?.user?.userId, state?.user?.email]
  )

  /**
   * NOTE on PROCEED_TO_NAVIGATION event:
   * If there is a pending navigation (navigationUrl exists)
   * update state, after state update for the user, in order to trigger that pending navigation.
   *
   * Note that this work due to the fact that dispatched actions are proceed in sequence by the reducer.
   */
  const setAuthUser = useCallback(
    async (authUser) => {
      let user
      if (authUser) {
        // NOTE: It's possible you won't be logged in here (refresh token expired), but there's no other way to do a
        //       pre-login without the possibility of it failing. :-P
        preLoginCallbacks.current.forEach((callback) => callback())

        setTokenCaller(authUser, 'user')
        user = await api.user.login()
        if (!user) {
          console.log('no user available', authUser)
          return
        }

        const { uid: userId } = authUser
        const { email } = user

        // TODO: Ideally we'd have the callbacks get whatever data they need from the context, but to do that this needs
        //       to wait for the dispatch to complete. Which is
        postLoginCallbacks.current.forEach((callback) =>
          callback(userId, email)
        )

        console.log(`setAuthUser user.state`, user.state)

        setUserAttributes({
          ...(user.id && { userId: user.id }),
          ...user
        })
      } else {
        resetTokenCaller('user')
      }
      dispatch({
        type: actions.SET_AUTH_USER,
        payload: { authUser, user }
      })
      if (navigationUrl) {
        dispatch({
          type: actions.PROCEED_TO_NAVIGATION,
          payload: true
        })
      }
    },
    [dispatch, navigationUrl]
  )

  const login = useCallback((email, password, url) => {
    return signInWithEmailAndPassword(auth, email, password).then(() => {
      setNavigationUrl(url)
    })
  }, [])

  const logout = useCallback(
    (url = '/login') => {
      preLogoutCallbacks.current.forEach((callback) => callback())
      return signOut(auth)
        .then(() => {
          dispatch({
            type: actions.LOGOUT,
            payload: { user: emptyUser, authUser: null }
          })
          clearLoggedInUserValues()
          setNavigationUrl(url)
        })
        .then(() =>
          postLogoutCallbacks.current.forEach((callback) => callback())
        )
    },
    [dispatch]
  )

  const sendPasswordResetEmailFn = useCallback((email) => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: getInstanceUrl()
    }
    return sendPasswordResetEmail(auth, email, actionCodeSettings).then(() => {
      return true
    })
  }, [])

  const confirmPasswordResetFn = useCallback((code, password) => {
    return confirmPasswordReset(auth, code, password).then(() => {
      return true
    })
  }, [])

  const setUserSettings = useCallback(
    (settings) => {
      const payload = {
        settings: { ...(state.user.settings || {}), ...settings }
      }

      api.user
        .patchUser({
          id: state.user.userId,
          auth: { authType: 'user' },
          data: payload,
          params: { identify: true }
        })
        .then(() => {
          updateUser(payload)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    [state.user.userId, state.user.settings, updateUser]
  )

  // Initialize header on load
  useEffect(() => {
    if (state.user.isAuth && locale && locale !== state.user?.settings?.locale) {
      setUserSettings({ locale })
    }
  }, [locale, setUserSettings, state.user.isAuth, state.user?.settings?.locale])

  /**
   * @param {object} data
   */
  const setUserProps = useCallback(
    (data, identify = false) => {
      return api.user
        .patchUser({
          id: state.user.userId,
          auth: { authType: 'user' },
          data,
          params: {
            identify
          }
        })
        .then(() => updateUser(data))
        .catch((e) => {
          console.log(e)
        })
    },
    [state.user.userId, updateUser]
  )

  const setUserCondition = useCallback(
    (condition) => {
      if (state.user.enrolled) return
      setUserProps({ condition })
    }, [state.user?.enrolled, setUserProps]
  )

  const enroll = useCallback(
    () => {
      const data = {
        subscriptionId: true,
        enrolled: true,
        state: {
          ...state.user?.state,
          type: 'enrolled',
        }
      }

      setUserProps(data)
    },
    [state.user?.state, setUserProps]
  )

  const updateAuthPasswordFn = useCallback((authUser, password) => {
    console.log(`userContext setAccountAuthPassword state.authUser`, authUser)
    return updatePassword(authUser, password)
      .then(() => {
        console.log(`userContext setAccountAuthPassword done`)
        return true
      })
      .catch((err) => {
        console.log(`userContext setAccountAuthPassword error`, err)
      })
  }, [])

  const getToken = useCallback(() => {
    if (state.authUser) {
      // noinspection JSIgnoredPromiseFromCall
      setTokenCaller(state.authUser, 'user')
      return state.authUser.getIdToken()
    }
    resetTokenCaller('user')
    return Promise.resolve(null)
  }, [state.authUser])

  /**
   *  Navigation is only triggered on a user change as an outcome of login or logout function call.
   *  Capture this change here and proceed to navigation is necessary.
   */
  useEffect(() => {
    if (navigationUrl && state.proceedToNavigation) {
      console.log(`userContext useEffect navigating to ${navigationUrl}`)
      // noinspection JSIgnoredPromiseFromCall
      navigate(navigationUrl)
      setNavigationUrl(null)
      dispatch({
        type: actions.PROCEED_TO_NAVIGATION,
        payload: false
      })
    }
  }, [dispatch, navigationUrl, state.proceedToNavigation])

  useEffect(() => {
    putInLocalStorage(USER, JSON.stringify(state.user))
  }, [state.user])

  useEffect(() => {
    //This is due to missing credentials while testing.
    //Makes the test to fail.
    //If we find a way to reuse fake client here as well, this has to be removed.
    //TODO: Remove when we add testing firestore client
    let unsubscribe = () => { }
    try {
      unsubscribe = onAuthStateChanged(auth, async (user) => {
        console.log(`firebase.auth().onAuthStateChanged user:`, user?.uid)
        await setAuthUser(user)

        return user
      })
    } catch (e) {
      console.log(e)
    }

    // Cleanup subscription on unmount
    return () => unsubscribe()
  }, [setAuthUser])

  const contextValue = useMemo(
    () => ({
      user: state.user,
      userInitialized: state.userInitialized,
      dispatch,
      updateUser,
      login,
      logout,
      sendPasswordResetEmail: sendPasswordResetEmailFn,
      confirmPasswordReset: confirmPasswordResetFn,
      setUserSettings,
      setUserProps,
      setUserCondition,
      enroll,
      getToken,
      updateAuthPassword: updateAuthPasswordFn,
      getUserState,
      refreshUserState,
      preLogoutCallbacks,
      postLogoutCallbacks,
      preLoginCallbacks,
      postLoginCallbacks
    }),
    [
      state.user,
      state.userInitialized,
      dispatch,
      updateUser,
      login,
      logout,
      sendPasswordResetEmailFn,
      confirmPasswordResetFn,
      setUserSettings,
      setUserProps,
      setUserCondition,
      enroll,
      getToken,
      updateAuthPasswordFn,
      getUserState,
      refreshUserState
    ]
  )

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () => useContext(UserContext)
