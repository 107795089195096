import { shouldLog } from './Helpers'
import { originalConsole } from './ConsoleRedirection'
import { levels, lowestPriorityLevel } from './Constants'

const assert = require('assert')

/**
 * Logger provides an interface for sending logs to different sinks (e.g., the console, Loggly, or LogRocket).
 */
export class Logger {
  /**
   * Constuctor.
   *
   * @param name - The name of the logger for use in metadata (e.g., searchable in Loggly)
   * @param level - The level required at minimum for a message to be sent by the transport. The message leve must be
   * equal to or higher priority than this level to be logged by the transport. Default is lowestPriorityLevel.
   * @param transports - The transports where logs will be sent to (e.g., the console, Loggly, or LogRocket).
   * @param meta - Additional metadata (an object) to add to all log objects sent.
   */
  constructor({
    name,
    level = lowestPriorityLevel,
    transports,
    meta = {}
  } = {}) {
    assert(name, '[Logger] name is required')
    assert(transports, '[Logger] transports is required')
    this.name = name
    this.level = level
    this.transports = transports
    this.meta = meta
  }

  /**
   * Create a child logger of this logger. A child-specific name can be specified. If you wish to update the metadata
   * you can use the separate updateMeta method.
   *
   * @param name - The name to use for the child logger
   * @param metadata - This is unused currently (used in our new API logging implementation which needs TS compatibility).
   */
  // eslint-disable-next-line
  child(name, metadata) {
    // const { level, ...restMeta } = metadata || {}
    const c = new Logger({
      name: name || this.name,
      transports: this.transports,
      meta: this.meta
    })

    // Don't want flush called on a child by mistake so don't allow it.
    c.flush = function () {
      assert(
        false,
        'ERROR: Do not call `flush` on child loggers. Call on the parent instead.'
      )
    }

    return c
  }

  /**
   * Update the meta data for the logger. This will overwrite the metadata so if you want to add metadata then you need
   * to use logger.updateMeta({ newMeta, ...logger.meta }).
   *
   * @param meta.level - The level required at minimum for a message to be sent by the transport. The message level
   * must be equal to or higher priority than this level to be logged by the transport.
   * @param meta - Any additional metadata you want to set on the logger.
   */
  updateMeta(meta = {}) {
    const { level, ...restMeta } = meta || {}
    if (level) this.level = level
    if (restMeta) this.meta = restMeta
  }

  /**
   * Flushes all pending logs in all transports on the logger.
   *
   * @param timeoutMs - The maximum time to wait for the flush to complete before giving up. The default is forever.
   *
   * NOTE: This is only available on the parent logger.
   *
   * @returns {Promise<Boolean>} true if the flush succeeded before the timeout, else false
   */
  async flush(timeoutMs = 2147483647) {
    const flushPromises = this.transports
      .map((t) => t.flush && t.flush(timeoutMs))
      .filter((promise) => promise)

    if (flushPromises.length > 0) {
      const allSucceeded = (await Promise.all(flushPromises)).reduce(
        (allSucceeded, thisSucceeded) => allSucceeded && thisSucceeded,
        true
      )
      // noinspection JSValidateTypes
      return allSucceeded
    }
  }

  /**
   * Logs a message to the logger at the given log level.
   *
   * @param level - The level to log the message at
   * @param message - The message to log
   * @param rest - The rest of the objects to add to the log message. This will be treated differently depending on the
   * transport
   */
  log(level, message, ...rest) {
    if (shouldLog({ logLevel: level, targetLevel: this.level })) {
      const timestamp = new Date()
      // Transport will determine whether or not it is handled synchronously or asynchronously...
      this.transports.forEach((t) => {
        try {
          t.log(timestamp, level, this.name, message, this.meta, ...rest)
        } catch (e) {
          // Don't want to use console redirection here to avoid an infinite loop...
          originalConsole().error(
            `[Logger] ERROR: Problem logging to transport=${t}. e=${e}. stacktrace=${e.stack}`
          )
        }
      })
    }
  }

  // These are just here to make typescript happy. The functions are overridden dynamically below.
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  error(message, ...rest) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  warn(message, ...rest) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  info(message, ...rest) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  verbose(message, ...rest) {}
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  debug(message, ...rest) {}

  // noinspection JSVoidFunctionReturnValueUsed
  /**
   * This creates "log level" functions for each log level in levelPriorities. E.g., this will make functions like:
   * `error`, `warn`, `info`, etc. Each one will take the same parameters as `log` above EXCEPT there should be no
   * level parameter passed since the level is handled by the function itself. So, calling:
   * ```
   *   logger.error('OH NOES!', someOtherParameters, ...)
   * ```
   *
   * is the same as:
   * ```
   *   logger.log('error', 'OH NOES!', someOtherParameters, ...)
   * ```
   */
  static _createLogLevelMethods = (() => {
    Object.values(levels).forEach((level) => {
      Logger.prototype[level] = function (message, ...rest) {
        // noinspection JSPotentiallyInvalidUsageOfClassThis
        this.log(level, message, ...rest)
      }
    })
  })()
}
