import React, { useCallback, useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Script from 'react-load-script'
import { useUserContext } from '../../contexts/User/userContext'
import { PushToDatalayer } from '../../utils/analytics-tracking'

export default function ChatWidgetSettings({ location }) {
  const { user } = useUserContext()
  const {
    content: { configuration }
  } = useStaticQuery(query)
  const _configuration = JSON.parse(
    ((configuration || {}).internal || {}).content || '{}'
  )

  if (!location) {
    console.log(`ChatWidgetSettings location`, location)
  }

  const { hostname, pathname } = location || { hostname: '', pathname: '/' }

  const [loaded, setLoaded] = useState(
    typeof window !== `undefined`
      ? window.document.getElementById('ze-snippet') !== null
      : false
  )
  const [shouldLoad, setShouldLoad] = useState(false)
  const [openCallbackInit, setOpenCallbackInit] = useState(false)

  if (
    typeof window !== `undefined` &&
    window.innerWidth > 640 &&
    _configuration.zESettings
  ) {
    window.zESettings = _configuration.zESettings
  }

  const setVisibility = useCallback(() => {
    if (typeof window === `undefined`) {
      return
    }

    const _pathname = pathname === '/' ? '/index' : pathname
    let displayWidget = true

    const key = Object.keys(_configuration).find((k) =>
      _pathname.startsWith(`/${k === '/' ? 'index' : k}`)
    )

    if (key && _configuration[key]) {
      displayWidget = _configuration[key].display
    } else if (
      hostname?.toLowerCase()?.includes('internal') ||
      hostname?.toLowerCase()?.includes('localhost')
    ) {
      displayWidget = false
    }

    if (window.zE && window.zE.hide) {
      if (displayWidget) {
        window.zE('webWidget', 'show')
      } else if (!displayWidget) {
        window.zE.hide()
      }
    } else {
      setTimeout(() => {
        setVisibility()
      }, 1000)
    }
  }, [_configuration, pathname])

  const setChatOpenCallback = useCallback(() => {
    if (!openCallbackInit && typeof window !== `undefined` && window.zE) {
      window.zE('webWidget:on', 'open', function () {
        PushToDatalayer({
          event: 'gtm.chat-widget-open'
        })
      })
      setOpenCallbackInit(true)
    }
  }, [openCallbackInit])

  useEffect(() => {
    if (loaded && shouldLoad) {
      setVisibility()
    }
  }, [location, loaded, setVisibility, shouldLoad])

  useEffect(() => {
    if (loaded && shouldLoad) {
      setChatOpenCallback()
    }
  }, [location, loaded, shouldLoad, setChatOpenCallback])

  useEffect(() => {
    // Make sure to identify the user anytime their email changes...
    identifyChatUser({ email: user.email, name: user.firstName })
  }, [user.email, user.firstName])

  useEffect(() => {
    if (typeof window !== `undefined` && window.zE) {
      window.zE('webWidget', 'updatePath')
    }
  }, [location])

  useEffect(() => {
    setTimeout(() => {
      setShouldLoad(true)
    }, 10000)
  }, [])

  //whitelist iframe for hotjar
  function configureIframe() {
    if (typeof window === `undefined`) {
      return
    }
    const iframe = window.document.getElementById('webWidget')
    if (iframe) {
      iframe.setAttribute('data-hj-allow-iframe', '')
    } else {
      setTimeout(() => {
        configureIframe()
      }, 1000)
    }
  }

  function handleOnLoad() {
    setLoaded(true)
    configureIframe()
  }

  return (
    <>
      {shouldLoad && !loaded && (
        <Script
          url="https://static.zdassets.com/ekr/snippet.js?key=05cd206a-d0d9-445a-b216-85bb93658fb2"
          attributes={{ id: 'ze-snippet' }}
          onLoad={handleOnLoad}
        />
      )}
    </>
  )
}

export const query = graphql`
  query ChatConfigurationQuery {
    content: contentfulEvergreenContent(
      readableId: { eq: "config-chat-widget" },
      node_locale: { eq: "en-US" }
    ) {
      ...EvergreenFragment
      configuration {
        internal {
          content
        }
      }
    }
  }
`

export function identifyChatUser(data) {
  if (typeof window !== `undefined` && window.zE) {
    window.zE('webWidget', 'identify', data)
  } else {
    setTimeout(() => {
      identifyChatUser(data)
    }, 1000)
  }
}
