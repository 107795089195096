import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

export default function ContentLink({ link, ...props }) {
  if (
    typeof window === `undefined` &&
    link.isInternal &&
    !link.url.startsWith('/')
  ) {
    console.error(
      'Link error. Internal link url must start with slash ("/")',
      link
    )
  }

  return link.isDisabled ? (
    <span className={props.className}>{link.title}</span>
  ) : link.isInternal && !props.target ? (
    link.image ? (
      <Link className={props.className} to={link.url} onClick={props.click}>
        <Img fixed={link.image.fixed} alt={link.image.title} />
      </Link>
    ) : (
      <Link
        className={props.className}
        style={props.style}
        to={link.url}
        onClick={props.click}
      >
        {link.title}
      </Link>
    )
  ) : (
    <a
      {...(props.target ? { target: props.target } : {})}
      className={props.className}
      style={props.style}
      href={link.url}
      onClick={props.click}
    >
      {link.title}
    </a>
  )
}
