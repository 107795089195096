import React from 'react'
import HeaderLogo from '../Layout/HeaderLogo'
import BackButton from '../common/BackButton'
import { isWebview } from '../../utils/helpers'
import { useEmployerContext } from '../../contexts/Employer/employerProvider'
import EmployerLogo from './EmployerLogo'

export default function SimpleHeader({ disableNavigation, backButton = true }) {
  const TopButton = () => {
    return backButton ? (
      <>
        <div className="w-1/6 mr-5 -m-5 sm:hidden">
          <div className="quiz-back-button">
            <BackButton
              disabled={disableNavigation}
              hasTitle={false}
              iconStyle={{ width: '57px' }}
            />
          </div>
        </div>
        <div className="hidden w-1/6 sm:block">
          <BackButton disabled={disableNavigation} hasTitle={true} />
        </div>
      </>
    ) : (
      <div className="block w-1/6 my-6" />
    )
  }

  const { employer } = useEmployerContext()
  const Logo = employer?.headerLogo ? () => <EmployerLogo image={employer.headerLogo?.fluid} /> : HeaderLogo

  return (
    <header className="sticky top-0 z-40 flex items-center justify-center w-full h-12 bg-white shadow-md sm:h-20 lg:py-2">
      <div className="flex items-center content-center justify-between w-full">
        <TopButton />
        {!isWebview && <Logo clickable={false} />}
        <div className="w-1/6" />
      </div>
    </header>
  )
}
