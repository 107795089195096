import { useState } from 'react'
import { logErr } from './logger'

const noopTransform = i => i
export const jsonTransform = a => JSON.parse(a)
export const dateTransform = d => new Date(d)

export const retrieveFromLocalStorageAndTransform = (
  key,
  fallbackValue,
  transform = noopTransform
) => {
  try {
    if (typeof window === 'undefined' || !key) {
      return fallbackValue
    }
    const item = window.localStorage.getItem(key)
    return item ? transform(item) : fallbackValue
  } catch (error) {
    logErr({
      err: error,
      msg: `Error in retrieveFromLocalStorageAndTransform.`,
      extra: { key }
    })
    return fallbackValue
  }
}

export const putInLocalStorage = (key, value) => {
  if (typeof window !== 'undefined' && key) {
    try {
      window.localStorage.setItem(key, value)
    } catch (error) {
      logErr({
        err: error,
        msg: `Error in putInLocalStorage`,
        extra: { key, value }
      })
    }
  }
}

export const removeFromLocalStorage = key => {
  if (typeof window !== 'undefined' && key) {
    try {
      window.localStorage.removeItem(key)
    } catch (error) {
      logErr({
        err: error,
        msg: `Error in removeFromLocalStorage`,
        extra: { key }
      })
    }
  }
}

export const removeFromLocalStorageArray = array => {
  for (const key of array) {
    removeFromLocalStorage(key)
  }
}

export const clearLocalStorage = () => {
  if (typeof window !== 'undefined') {
    try {
      window.localStorage.clear()
    } catch (error) {
      logErr({
        err: error,
        msg: `Error in clear localStorage`
      })
    }
  }
}

// TODO: We should stop using this and instead only access local storage through a context and let the context handle the state management.
/**
 * @deprecated
 * This causes rerenders when the setter is injected as prop.
 * TODO: Update react and node version and use @uidotdev/usehooks
 */
export default function useLocalStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    return retrieveFromLocalStorageAndTransform(key, initialValue, item => item)
  })

  const setValue = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)
      putInLocalStorage(key, valueToStore)
    } catch (error) {
      logErr({
        err: error,
        msg: `Error in useLocalStorage setter.`,
        extra: { key }
      })
    }
  }

  return [storedValue, setValue]
}

// TODO: We should stop using this and instead only access local storage through a context and let the context handle the state management.
export function useLocalArrayStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(() => {
    return retrieveFromLocalStorageAndTransform(
      key,
      initialValue,
      jsonTransform
    )
  })

  const setValue = value => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)

      putInLocalStorage(key, JSON.stringify(valueToStore))
    } catch (error) {
      logErr({
        err: error,
        msg: `Error in useLocalArrayStorage setter.`,
        extra: { key }
      })
    }
  }

  return [storedValue, setValue]
}
