import { useMemo } from 'react'
import { useEmployerContext } from '../contexts/Employer/employerProvider'
import mskCondition from '../images/conditions/condition-msk.svg'
import sleepCondition from '../images/conditions/condition-sleep.svg'
import digestiveCondition from '../images/conditions/condition-digestive.svg'
import covidCondition from '../images/conditions/condition-long-covid.svg'
import mentalCondition from '../images/conditions/condition-mental-health.svg'
import cancerSurvivorshipCondition from '../images/conditions/condition-cancer-survivorship.svg'
import { useConditionContext } from '../components/common/ConditionProvider'

export const links = [
  {
    url: 'https://www.goodpath.com/conditions/back-pain',
    title: 'Back & Joint Pain',
    icon: mskCondition,
    alt: 'Back & Joint Pain icon.',
    slug: 'back-pain',
    isInternal: true
  },
  {
    url: '/conditions/sleep',
    title: 'Sleep',
    icon: sleepCondition,
    alt: 'Sleep icon.',
    slug: 'sleep',
    isInternal: true
  },
  {
    url: '/conditions/ibs',
    title: 'Digestive',
    icon: digestiveCondition,
    alt: 'Digestive icon.',
    slug: 'ibs',
    isInternal: true
  },
  {
    url: '/conditions/long-covid',
    title: 'Long COVID',
    icon: covidCondition,
    alt: 'Long COVID icon.',
    slug: 'long-covid',
    isInternal: true
  },
  {
    url: '/conditions/mental-health',
    title: 'Mental Health',
    icon: mentalCondition,
    alt: 'Mental health icon.',
    slug: 'mental-health',
    isInternal: true
  },
  {
    url: 'https://www.goodpath.com/conditions/cancer-survivorship',
    title: 'Cancer Survivorship',
    icon: cancerSurvivorshipCondition,
    alt: 'Cancer Survivorship icon.',
    slug: 'cancer-survivorship',
    isInternal: true
  }
]
