import React from 'react'
import 'firebase/auth'
import { UserProvider } from './src/contexts/User/userContext'
import { EmployerProvider } from './src/contexts/Employer/employerProvider'

import 'primereact/resources/themes/saga-blue/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'
import CountryProvider from './src/components/common/CountryProvider'
import { LocalizationProvider } from './src/contexts/Localization/localizationProvider'
import { LogRocketComponent } from './src/components/LogRocketComponent'

// NOTE: Code requiring very early initialization was moved to `gatsby-early-initialization.js. See note in there for
//  details. Any code that must run before any of our other code should be put in there.

export const wrapRootElement = ({ element }) => {
  return (
    <LocalizationProvider>
      <UserProvider>
        <EmployerProvider>
          <CountryProvider>
            <LogRocketComponent />
            {element}
          </CountryProvider>
        </EmployerProvider>
      </UserProvider>
    </LocalizationProvider>
  )
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null
}

// This addresses the problem we see in netlify people are hitting when we deploy a new version of the website. It
// causes the page to load with a JS error due to problems loading "chunks" or something (from the bowels of the gatsby
// code). This will cause the page to reload automatically which usually fixes the issue. See here for the fix and
// the whole thread in general for more details: https://github.com/gatsbyjs/gatsby/issues/18866#issuecomment-844449917
//
// NOTE: We're also filtering the event from sentry based on this:
//   https://github.com/gatsbyjs/gatsby/issues/18866#issuecomment-845531333
// and this:
//   https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
//
// Specifically, in `gatsby-config.js` where we initialize Sentry, we're preventing the exception from
// being sent to sentry if it matches the same criteria as below using the method from the latter link above (gatsby's
// sentry plugin handles calling `Sentry.init`).
//
let nextRoute = ``
export const onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname
}
window.addEventListener('unhandledrejection', (event) => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute
    }
  }
})

/*
  https://www.gatsbyjs.com/plugins/gatsby-plugin-why-did-you-render/

  To enable in components add
  Component.whyDidYouRender = true
*/

// export const onClientEntry = () => {
//   if (process.env.NODE_ENV !== 'production') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render')
//     whyDidYouRender(React, {
//       trackAllPureComponents: true
//     })
//   }
// }
