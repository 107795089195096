import { ConsoleTransport } from '../../../common/logging/transports/ConsoleTransport'
import LogRocket from 'logrocket'

/**
 * Transport that sends logs to LogRocket's log session.
 *
 * This uses the same format capability as ConsoleTransport as it derives from it. See @ConsoleTransport for details.
 */
export class LogRocketTransport extends ConsoleTransport {
  /**
   * Constructor
   *
   * @param level - The level a log must be at (or higher) to be logged to the console.
   * @param format - The format string to be used to format the message. See below for more info.
   * @param meta - Additional metadata (an object) to add to all log objects sent.
   *
   * Formatting: This uses the same format capability as ConsoleTransport as it derives from it. See @ConsoleTransport
   * for details.
   */
  constructor({ level, format = '%s-message', meta } = {}) {
    super({ level, meta, format })
  }

  getOriginalConsole() {
    return LogRocket
  }
}
