import React, { createContext, useContext, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'

const siteConf = process.env.GATSBY_CONTENTFUL_SITE_CONFIG || 'production'

const defaultState = {
  conditions: []
} as ContextType

const ConditionsContext = createContext<ContextType>(defaultState)

const query = graphql`
  query ConditionProviderQuery {
    allContentfulSiteConfig {
      nodes {
        node_locale
        environmentName
        conditions {
          slug
          fullTitle
          subtitle
          icon1 {
            file {
              url
            }
          }
        }
      }
    }
  }
`

// Condition component provider to expose condition relative data
const ConditionProvider = ({ children }: Provider) => {
  const data = useStaticQuery(query)
  const { content: conditionData } = useLocalizedContent<any>(data)

  const conditions = useMemo(
    () =>
      conditionData?.allContentfulSiteConfig?.nodes?.find(
        (config: any) => config.environmentName === siteConf
      )?.conditions,
    [conditionData?.allContentfulSiteConfig?.nodes]
  )

  return (
    <ConditionsContext.Provider
      value={{
        conditions
      }}
    >
      {children}
    </ConditionsContext.Provider>
  )
}

export default ConditionProvider

export const useConditionContext = () => useContext(ConditionsContext)

export type ContextType = {
  conditions: Condition[]
}

type Provider = {
  initialState?: ContextType
  children: React.ReactNode
}

type Condition = {
  slug: string
}
