module.exports = {
  prefix: '',
  important: false,
  separator: ':',
  theme: {
    extend: {
      outline: {
        'violet-solid': ['2px solid #372C72'],
        violet: ['2px solid rgba(55, 44, 114, 0.5)', '4px']
      },
      gridTemplateColumns: {
        '60-40': '60% 1fr',
        '40-60': '40% 1fr',
        '30-70': '30% 1fr',
        '70-30': '70% 1fr'
      }
    },
    screens: {
      xs: '321px',
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      xxl: '1600px',
      xxxl: '1800px',
      short: {
        raw: '(max-height:800px)'
      }
    },
    colors: {
      primary: {
        light: '#eae1f9',
        base: '#372c72',
        hover: '#bcb3da',
        dark: '#2F145E',
        purple: '#867CB0'
      },
      transparent: 'transparent',

      black: '#252525',
      'light-grey': '#d8d8d8',
      'medium-grey': '#53565a',
      'space-grey': '#828384',
      'black-light': '#1f1f1f',
      white: '#fff',

      brand: {
        white: '#ffffff',
        purple: '#372c72',
        peach: '#f0c8c2',
        'light-teal': '#b9ccd5',
        'light-teal-300': '#EAF0F3',
        'pale-grey': '#f8f8fa',
        'light-grey': '#d8d8d8',
        'medium-grey': '#53565a',
        'charcoal-grey': '#252525',
        black: '#000000'
      },

      partner: {
        yamaha: '#d40000'
      },

      gp: {
        // colors according to Dimitris pallet with names
        // primary purple color
        violet: '#372c72',
        lavender: '#736b9c',
        manatee: '#9b95b8',
        galaxy: '#c3bfd4',
        magnolia: '#ebe9f1',
        ghost: '#f5f4f8',
        // secondary red color
        grapefruit: '#db786b',
        pink: '#e6a097',
        orchid: '#edbbb5',
        quicksand: '#f4d6d2',
        rubie: '#fbf1f0',
        ivory: '#fdf8f7',
        // secondary yellow
        honey: '#eec944',
        pancake: '#f3d97c',
        dahlia: '#f6e4a1',
        champagne: '#faefc7',
        floral: '#fdf9ec',
        milky: '#fefcf5',
        // secondary grey
        slate: '#60797f',
        tower: '#8fa1a5',
        cement: '#afbcbf',
        sleet: '#cfd7d8',
        fog: '#eff1f2',
        snow: '#f7f8f8',
        //secondary blue
        larimar: '#1e8da7',
        moonstone: '#61afc1',
        seaweed: '#8ec6d3',
        mint: '#bbdde4',
        frost: '#e8f3f6',
        swan: '#f4f9fb',
        // Neutrals
        'eel-black': '#2f2d39',
        'dove-grey': '#5c5b64',
        'santas-grey': '#a2a2a7',
        alabaster: '#f9f9f9',
        white: '#ffffff',

        // ----------- here are old colors we should avoid using them -----------
        'violet-120': '#251E4D',
        'violet-70': '#736b9c',
        'violet-50': '#9b95b8',
        'violet-30': '#c3bfd4',
        'violet-10': '#ebe9f0',
        'violet-5': '#F5F4F8',
        periwinkle: '#6667AB',
        'periwinkle-10': '#EAE9F0',
        'periwinkle-5': '#F5F5FA',
        'periwinkle-120': '#3D3E70',
        //
        'honey-90': '#FFD166',
        'honey-70': '#f3d97c',
        'honey-50': '#f6e4a1',
        'honey-30': '#f9eec6',
        'honey-10': '#fdf9ec',
        //
        'slate-70': '#8fa1a5',
        'slate-50': '#afbcbf',
        'slate-30': '#cfd7d8',
        'slate-10': '#eff1f2',
        //
        'grapefruit-70': '#e5a097',
        'grapefruit-50': '#edbbb5',
        'grapefruit-30': '#f4d6d2',
        'grapefruit-10': '#fbf1f0',
        //
        'off-white': '#FCFBFE',
        'grey-5': '#f3f3f4',
        'grey-10': '#e7e7e8',
        'grey-20': '#d1d0d3',
        'grey-30': '#b9b8bc',
        'grey-40': '#a2a2a7',
        'grey-50': '#8b8a90',
        'grey-60': '#74737b',
        'grey-70': '#5c5b64',
        'grey-80': '#46444f',
        'grey-90': '#2f2d39',
        'grey-95': '#f2f2f2',
        'grey-96': '#f2f2f8',
        'grey-100': '#171523',
        //
        purple: '#453D77',
        'purple-90': '#BBBDF6',
        //
        green: '#218600',
        'green-70': '#7BE0AD',
        //
        blue: '#1E8DA7',
        'blue-10': '#E8F3F6'
      },

      grey: {
        dark: '#5F5F5F',
        base: '#9F9F9F',
        md: '#BFBFBF',
        lightmd: '#EEEEEE',
        light: '#E5E5E5',
        lighter: '#F5F5F5',
        lightest: '#FAFAFA',
        custom: '#46494C',
        plan: '#f9f8fb',
        border: '#6D7278',
        borderNew: '#898a8b',
        chklight: '#979797',
        100: '#F8F8FA',
        112: '#d1d1d2',
        200: '#F1F2F6',
        555: '#a5a5a5',
        557: '#c3c3c5',
        667: '#676b70'
      },

      red: {
        100: '#fff5f5',
        200: '#fed7d7',
        300: '#feb2b2',
        400: '#fc8181',
        500: '#f56565',
        600: '#e53e3e',
        700: '#c53030',
        800: '#9b2c2c',
        900: '#742a2a'
      },
      orange: {
        base: '#EE624A',
        100: '#fffaf0',
        200: '#feebc8',
        300: '#fbd38d',
        400: '#f6ad55',
        500: '#ed8936',
        600: '#dd6b20',
        700: '#c05621',
        800: '#9c4221',
        900: '#7b341e',
        link: '#CA3F24'
      },
      yellow: {
        100: '#fffff0',
        200: '#fefcbf',
        300: '#faf089',
        400: '#f6e05e',
        500: '#ecc94b',
        600: '#d69e2e',
        700: '#b7791f',
        800: '#975a16',
        900: '#744210',
        '082': '#f0c8c2'
      },
      green: {
        100: '#f0fff4',
        200: '#c6f6d5',
        300: '#9ae6b4',
        400: '#68d391',
        500: '#48bb78',
        600: '#38a169',
        700: '#2f855a',
        800: '#276749',
        900: '#22543d'
      },
      teal: {
        100: '#e6fffa',
        200: '#b2f5ea',
        250: '#bcb1d6',
        300: '#81e6d9',
        400: '#4fd1c5',
        500: '#38b2ac',
        600: '#319795',
        700: '#2c7a7b',
        800: '#285e61',
        900: '#234e52'
      },
      blue: {
        100: '#ebf8ff',
        150: '#b9ccd5',
        200: '#bee3f8',
        300: '#90cdf4',
        400: '#63b3ed',
        500: '#4299e1',
        600: '#3182ce',
        700: '#2b6cb0',
        800: '#2c5282',
        900: '#2a4365'
      },
      indigo: {
        100: '#ebf4ff',
        200: '#c3dafe',
        300: '#a3bffa',
        400: '#7f9cf5',
        500: '#667eea',
        600: '#5a67d8',
        700: '#4c51bf',
        800: '#434190',
        900: '#3c366b'
      },
      purple: {
        50: '#bcb3da',
        100: '#faf5ff',
        150: '#e4dded',
        200: '#e9d8fd',
        300: '#d6bcfa',
        350: '#bcb3da',
        400: '#b794f4',
        500: '#9f7aea',
        600: '#805ad5',
        700: '#6b46c1',
        800: '#553c9a',
        900: '#44337a',
        light: '#745aa3',
        685: '#a68ec5'
      },
      pink: {
        100: '#fff5f7',
        200: '#fed7e2',
        300: '#fbb6ce',
        400: '#f687b3',
        500: '#ed64a6',
        600: '#d53f8c',
        700: '#b83280',
        800: '#97266d',
        900: '#702459'
      },
      antique: '#fcf4ec',

      //Brand Styles

      ui: {
        error: '#ab0909',
        'error-brighter': '#FEE7E7',
        'error-darker': '#790606',
        success: '#1F8236',
        'success-brighter': '#DEF7E4',
        'success-darker': '#165B26',
        warning: '#F5E579',
        morning: '#F9DF86',
        'morning-active': '#BF6B2A',
        midday: '#DE998C',
        'midday-active': '#AA280B',
        evening: '#847DCF',
        'evening-active': '#2F23B3',
        bed: '#8482A1',
        'bed-active': '#100F14',
        slate: '#CFD9E2',
        'slate-active': '#6686A3',
        periwinkle: '#534B8D',
        // App Backgrounds for time of day
        // Morning | bg-gradient-to-b from-ui-sky to-ui-flax
        // Afternoon | bg-gradient-to-b from-ui-flax to-ui-tangerine
        // Evening | bg-gradient-to-b from-ui-eggplant to-ui-midnight
        sky: '#5ACDE7',
        flax: '#EEE59B',
        tangerine: '#E7955A',
        eggplant: '#573567',
        midnight: '#1F1D10',
        carrot: '#E79443',
        lemon: '#FAE384',
        lavender: '#EBEBF9'
      },

      //new styleguide (add postfix __gp)
      primary__gp: {
        100: '#dbd3eb',
        200: '#bcb3da',
        300: '#372c72'
      },
      secondary__gp: {
        100: '#f0c8c2',
        200: '#b9ccd5'
      },
      tertiary__gp: {
        100: '#ffffff',
        200: '#f8f8fa',
        300: '#d8d8d8',
        400: '#53565a',
        500: '#252525',
        600: '#000000'
      },
      mp_templates: {
        0: '#D67AF1',
        1: '#FF769F',
        2: '#FF9066',
        3: '#3AD0AE'
      }
    },
    spacing: {
      px: '1px',
      '2px': '2px',
      '25px': '25px',
      0: '0',
      1: '0.25rem',
      2: '0.5rem',
      3: '0.75rem',
      4: '1rem',
      5: '1.25rem',
      6: '1.5rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      14: '3.5rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      26: '6.5rem',
      28: '7rem',
      32: '8rem',
      36: '9rem',
      40: '10rem',
      48: '12rem',
      56: '14rem',
      64: '16rem',
      72: '18rem',
      80: '20rem',
      104: '26rem',
      90: '90%',
      58: '58px',
      500: '500px',
      600: '600px',
      650: '650px',
      700: '700px',
      800: '800px',
      //new styleguide (add postfix __gp)
      '05__gp': '0.25rem',
      '045__gp': '0.45rem',
      '1__gp': '0.5rem',
      '2__gp': '1rem',
      '3__gp': '1.5rem',
      '4__gp': '2rem',
      '5__gp': '2.5rem',
      '6__gp': '3rem',
      '7__gp': '3.5rem',
      '8__gp': '4rem',
      '9__gp': '4.5rem'
    },
    backgroundColor: (theme) => theme('colors'),
    backgroundPosition: {
      bottom: 'bottom',
      center: 'center',
      left: 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      right: 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      top: 'top'
    },
    backgroundSize: {
      auto: 'auto',
      cover: 'cover',
      contain: 'contain'
    },
    borderColor: (theme) => ({
      ...theme('colors'),
      default: theme('colors.gray.300', 'currentColor')
    }),
    borderRadius: {
      none: '0',
      sm: '0.125rem',
      md: '0.2rem',
      default: '0.25rem',
      innerlg: '0.4rem',
      lg: '0.5rem',
      12: '0.75rem',
      xlg: '1rem',
      20: '1.25rem',
      xmlg: '1.5rem',
      xxlg: '2rem',
      full: '9999px',
      chkin: '5px',
      chkout: '7px',
      topBanner: '0 0 16px 16px'
    },
    borderWidth: {
      default: '1px',
      0: '0',
      14: '1.4px',
      2: '2px',
      3: '3px',
      4: '4px',
      6: '6px',
      8: '8px'
    },
    boxShadow: {
      default:
        '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      md:
        '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      lg:
        '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      xl:
        '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
      outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
      bordered: '0 0 0 4px #372c72;',
      none: 'none',
      video: ' -21px 22px 42px 0px rgba(0, 0, 0, 0.25)',
      stickyQuizContainer: '0px -1px 2px 1px rgba(0, 0, 0, 0.2)',
      top: '0px -4px 16px -8px rgba(47, 45, 57, 1)',
      uniform: '0px 0px 16px 4px rgba(23, 21, 35, 0.10)'
    },
    container: {},
    cursor: {
      auto: 'auto',
      default: 'default',
      pointer: 'pointer',
      wait: 'wait',
      text: 'text',
      move: 'move',
      'not-allowed': 'not-allowed'
    },
    fill: {
      current: 'currentColor'
    },
    flex: {
      1: '1 1 0%',
      auto: '1 1 auto',
      initial: '0 1 auto',
      none: 'none'
    },
    flexGrow: {
      0: '0',
      default: '1'
    },
    flexShrink: {
      0: '0',
      default: '1'
    },
    fontFamily: {
      sans: [
        'sofia-pro',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"'
      ],
      serif: ['Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
      mono: [
        'Menlo',
        'Monaco',
        'Consolas',
        '"Liberation Mono"',
        '"Courier New"',
        'monospace'
      ]
    },
    fontSize: {
      xxxs: '0.35rem',
      xxs: '0.5rem',
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '4xl': '2.25rem',
      '4xxl': '2.6rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '5.5rem',
      80: '80px',
      150: '150px',
      210: '210px',
      17: '17px',
      21: '21px',
      //new styleguide (add postfix __gp)
      xs__gp: '0.75rem',
      sm__gp: '0.875rem',
      base__gp: '1rem',
      lg__gp: '1.125rem',
      xl__gp: '1.5rem',
      '2xl__gp': '1.875rem'
    },
    fontWeight: {
      hairline: '100',
      thin: '200',
      light: '300',
      normal: '400',
      medium: '500',
      semibold: '600',
      bold: '700',
      extrabold: '800',
      black: '900'
    },
    height: (theme) => ({
      auto: 'auto',
      fit: 'fit-content',
      ...theme('spacing'),
      full: '100%',
      screen: '100vh',
      '85vh': '85vh',
      '400px': '400px',
      500: '500px',
      600: '600px',
      chkround: '29px',
      chkroundmin: '20px',
      chkroundinner: '12px',
      chkroundinnermin: '6px',
      14: '3.5rem',
      40: '10rem',
      44: '11rem',
      '2p': '2px',
      24: '24rem',
      '50r': '50rem'
    }),
    inset: {
      '-6': '-1.5rem',
      '-7': '-1.75rem',
      '-8': '-2rem',
      '-9': '-2.25rem',
      '-10': '-2.5rem',
      '-20': '-5rem',
      '-32': '-8rem',
      minus10: '-10px',
      0: '0',
      2: '0.5rem',
      4: '1rem',
      6: '1.5rem',
      9: '2.25rem',
      12: '3rem',
      16: '4rem',
      20: '5rem',
      32: '8rem',
      36: '9rem',
      48: '12rem',
      'm1/12': '-8.333333%',
      'm2/12': '-16.666667%',
      'm3/12': '-25%',
      'm4/12': '-33.333333%',
      'm5/12': '-41.666667%',
      'm6/12': '-50%',
      'm7/12': '-58.333333%',
      'm8/12': '-66.666667%',
      'm9/12': '-75%',
      'm10/12': '-83.333333%',
      'm11/12': '-91.666667%',
      'm12/12': '-100%',
      '1/2': '50%',
      '1/5': '20%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      '12/12': '100%',
      auto: 'auto'
    },
    letterSpacing: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em'
    },
    lineHeight: {
      none: '1',
      tight: '1.25',
      snug: '1.375',
      normal: '1.5',
      relaxed: '1.625',
      loose: '1.875',
      '38px': '38px',
      //new styleguide (add postfix __gp)
      less__gp: '.75rem',
      none__gp: '1rem',
      tight__gp: '1.25',
      snug__gp: '1.375rem',
      snugger__gp: '1.625rem',
      normal__gp: '1.75rem',
      relaxed__gp: '2.125rem',
      loose__gp: '2.5rem'
    },
    listStyleType: {
      none: 'none',
      disc: 'disc',
      decimal: 'decimal'
    },
    margin: (theme, { negative }) => ({
      auto: 'auto',
      ...theme('spacing'),
      ...negative(theme('spacing')),
      '1px': '1px',
      '2px': '2px',
      '18px': '18px',
      '25px': '25px',
      chkrnd: '7px',
      chkrndmin: '5px',
      //new styleguide (add postfix __gp)
      '05__gp': '0.25rem',
      '1__gp': '0.5rem',
      '2__gp': '1rem',
      '3__gp': '1.5rem',
      '4__gp': '2rem',
      '5__gp': '2.5rem',
      '6__gp': '3rem',
      '7__gp': '3.5rem',
      '8__gp': '4rem',
      '9__gp': '4.5rem',
      '5/2': '5.2rem'
    }),
    maxHeight: {
      0: '0',
      full: '100%',
      432: '432px',
      screen: '100vh',
      24: '24px',
      56: '56px',
      70: '70px',
      80: '80px',
      100: '100px',
      150: '150px',
      200: '200px',
      280: '280px',
      300: '300px',
      400: '400px',
      500: '500px',
      600: '600px',
      700: '700px',
      850: '850px'
    },
    maxWidth: {
      '1/4': '25%',
      '1/2': '50%',
      '3/4': '75%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      '12/12': '100%',
      content: '73.5rem', //placed first for lower specificity
      '10r': '10rem',
      '16r': '16rem',
      8: '2rem',
      10: '2.5rem',
      12: '3rem',
      16: '4rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
      xxs: '16rem',
      xs: '20rem',
      sm: '24rem',
      md: '28rem',
      lg: '32rem',
      xl: '36rem',
      '2xl': '42rem',
      '3xl': '48rem',
      '4xl': '56rem',
      '5xl': '64rem',
      '6xl': '72rem',
      '7xl': '80rem',
      '8xl': '90rem',
      '10xl': '125rem',
      94: '94%',
      full: '100%',
      65: '65px',
      70: '70px',
      100: '100px',
      150: '150px',
      200: '200px',
      240: '240px',
      300: '300px',
      380: '380px',
      400: '400px',
      500: '500px',
      550: '550px',
      600: '600px',
      650: '650px',
      700: '700px',
      760: '760px',
      800: '800px',
      2000: '2000px',
      ch: '65ch'
    },
    minHeight: {
      0: '0',
      20: '5rem',
      34: '6rem',
      45: '45px',
      60: '60px',
      100: '100px',
      125: '125px',
      140: '140px',
      150: '150px',
      200: '200px',
      240: '240px',
      300: '300px',
      380: '380px',
      400: '400px',
      432: '432px',
      500: '500px',
      600: '600px',
      650: '650px',
      700: '700px',
      800: '800px',
      80: '80vh',
      full: '100%',
      screen: '100vh'
    },
    minWidth: {
      0: '0',
      20: '5rem',
      '10r': '10rem',
      50: '50px',
      100: '100px',
      150: '150px',
      200: '200px',
      240: '240px',
      300: '300px',
      380: '380px',
      400: '400px',
      500: '500px',
      550: '550px',
      600: '600px',
      650: '650px',
      700: '700px',
      760: '760px',
      800: '800px',
      full: '100%'
    },
    objectPosition: {
      bottom: 'bottom',
      center: 'center',
      left: 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      right: 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      top: 'top'
    },
    opacity: {
      0: '0',
      10: '0.10',
      25: '0.25',
      50: '0.5',
      75: '0.75',
      100: '1'
    },
    order: {
      first: '-9999',
      last: '9999',
      none: '0',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12'
    },
    padding: (theme) => ({ ...theme('spacing'), px: '1px' }),
    placeholderColor: (theme) => theme('colors'),
    stroke: {
      current: 'currentColor'
    },
    textColor: (theme) => theme('colors'),
    width: (theme) => ({
      auto: 'auto',
      ...theme('spacing'),
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      '12/12': '100%',
      full: '100%',
      14: '3.5rem',
      20: '5rem',
      24: '6rem',
      32: '8rem',
      34: '8,725rem',
      36: '9rem',
      44: '11rem',
      '52r': '13rem',
      64: '16rem',
      30: '30%',
      45: '45%',
      47: '47%',
      '48p': '48%',
      49: '49%',
      52: '52%',
      55: '55%',
      100: '100px',
      400: '400px',
      screen: '100vw',
      chklft: '55%',
      chkrght: '40%',
      chkround: '29px',
      chkroundmin: '20px',
      chkroundinner: '12px',
      chkroundinnermin: '6px'
    }),
    zIndex: {
      auto: 'auto',
      0: '0',
      10: '10',
      20: '20',
      30: '30',
      40: '40',
      50: '50',
      60: '60',
      70: '70',
      102: '102',
      999: '999'
    }
  },
  variants: {
    accessibility: ['responsive', 'focus'],
    alignContent: ['responsive'],
    alignItems: ['responsive'],
    alignSelf: ['responsive'],
    appearance: ['responsive'],
    backgroundAttachment: ['responsive'],
    backgroundColor: ['responsive', 'hover', 'odd', 'even', 'focus'],
    backgroundPosition: ['responsive'],
    backgroundRepeat: ['responsive'],
    backgroundSize: ['responsive'],
    borderCollapse: ['responsive'],
    borderColor: ['responsive', 'hover', 'focus'],
    borderRadius: ['responsive'],
    borderStyle: ['responsive'],
    borderWidth: ['responsive', 'last'],
    boxShadow: ['responsive', 'hover', 'focus'],
    cursor: ['responsive'],
    display: ['responsive'],
    fill: ['responsive'],
    flex: ['responsive'],
    flexDirection: ['responsive'],
    flexGrow: ['responsive'],
    flexShrink: ['responsive'],
    flexWrap: ['responsive'],
    float: ['responsive'],
    fontFamily: ['responsive'],
    fontSize: ['responsive'],
    fontSmoothing: ['responsive'],
    fontStyle: ['responsive'],
    fontWeight: ['responsive', 'hover', 'focus'],
    height: ['responsive'],
    inset: ['responsive'],
    justifyContent: ['responsive'],
    letterSpacing: ['responsive'],
    lineHeight: ['responsive'],
    listStylePosition: ['responsive'],
    listStyleType: ['responsive'],
    margin: ['responsive'],
    maxHeight: ['responsive'],
    maxWidth: ['responsive'],
    minHeight: ['responsive'],
    minWidth: ['responsive'],
    objectFit: ['responsive'],
    objectPosition: ['responsive'],
    opacity: ['responsive', 'hover', 'focus'],
    order: ['responsive'],
    outline: ['responsive', 'focus'],
    overflow: ['responsive'],
    padding: ['responsive'],
    placeholderColor: ['responsive', 'focus'],
    pointerEvents: ['responsive'],
    position: ['responsive'],
    resize: ['responsive'],
    stroke: ['responsive'],
    tableLayout: ['responsive'],
    textAlign: ['responsive'],
    textColor: [
      'responsive',
      'hover',
      'focus',
      'active',
      'group-hover',
      'group-focus'
    ],
    textDecoration: ['responsive', 'hover', 'focus'],
    textTransform: ['responsive'],
    userSelect: ['responsive'],
    verticalAlign: ['responsive'],
    visibility: ['responsive'],
    whitespace: ['responsive'],
    width: ['responsive'],
    wordBreak: ['responsive'],
    zIndex: ['responsive', 'hover']
  },
  corePlugins: {},
  plugins: [],
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true,
    defaultLineHeights: true,
    standardFontWeights: true
  }
}
