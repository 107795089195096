import { SynchronousTransport } from '../SynchronousTransport'
import { levelPriorities } from '../Constants'
import { originalConsole } from '../ConsoleRedirection'

const assert = require('assert')

// No flatmap in node.js??? Oh well, let's add it...
const flatMap = (f, xs) => xs.reduce((acc, x) => acc.concat(f(x)), [])

if (!Array.prototype.flatMap) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.flatMap = function(f) {
    return flatMap(f, this)
  }
}

/**
 * Simple transport that logs to the console. The way the message is formatted is configurable in the construtor.
 */
export class ConsoleTransport extends SynchronousTransport {
  /**
   * Constructor
   *
   * @param level - The level a log must be at (or higher) to be logged to the console.
   * @param format - The format string to be used to format the message. See below for more info.
   * @param meta - Additional metadata (an object) to add to all log objects sent.
   *
   * Formatting:
   * Formatting works similar to how console log formatting works (e.g., see
   * https://developer.mozilla.org/en-US/docs/Web/API/console#Outputting_text_to_the_console), however you must append
   * `-VARNAME` to the substitution specifier to indicate what field from the log object metadata should be used when
   * substituting. You can use any property provided in the meta object passed to the Logger or Transport or a property
   * directly in the log object (like timestamp, message, name, or level).
   *
   * NOTE: The timestamp property is handled specially in that it will format the timestamp to an ISO string.
   */
  constructor({ level, format = '%s-message', meta } = {}) {
    super({ level, meta })
    this.formatDetails = this._parseFormat(format)
  }

  getOriginalConsole() {
    return originalConsole()
  }

  send({ logs }) {
    for (const log of logs) {
      const priority = levelPriorities[log.level]
      const msg = this._formatMessage(log)
      const originalConsole = this.getOriginalConsole()

      assert(
        priority,
        `[ConsoleTransport] priority=${priority} is not a valid level from levelPriorities`
      )

      switch (priority) {
        case levelPriorities.error:
          originalConsole.error(msg.message, ...msg.values)
          break

        case levelPriorities.warn:
          originalConsole.warn(msg.message, ...msg.values)
          break

        default:
        case levelPriorities.info:
          originalConsole.info(msg.message, ...msg.values)
          break

        case levelPriorities.verbose:
        case levelPriorities.debug:
          originalConsole.debug(msg.message, ...msg.values)
          break
      }
    }
  }

  /**
   * Returns a format object with a format string and variable names.
   *
   * @param format - The input format string (with variable names in the specifiers)
   * @returns {{format, vars}}
   * @private
   */
  _parseFormat(format) {
    const specifierRegex = /(%[oOdisf])-([A-Za-z0-9_]+)/g

    // Find the variable name specifiers. E.g., [ '%s-timestamp', '%s-message' ]
    const specifiers = format.match(specifierRegex)

    // Take the var name out of the specifiers in the format since console.log doesn't use that.
    const consoleFormat = format.replace(specifierRegex, '$1')

    // And store of (in order) the variables that will get replaced.
    const vars = specifiers.map(s => s.substring(3))

    return { format: consoleFormat, vars }
  }

  /**
   * Formats a log based on the format details.
   *
   * @param log - The log
   * @returns {{values: unknown[], message}}
   * @private
   */
  _formatMessage(log) {
    // TODO: I feel like there's gotta be something from a library that could handle this stuff for us...
    const formatSpecifierRegex = new RegExp('%[0-9]*(?:\\.[0-9])?[oOdisf]', 'g')

    // Get the values based on the specifiers from the format string
    let numRestUsedForMsg = 0
    const values = this.formatDetails.vars.flatMap(v => {
      switch (v) {
        case 'timestamp':
          return log.timestamp.toISOString()

        case 'message': {
          // If message has replacement specifiers then put N values from "rest" parameters sent in log for the N
          // replacement specifiers found.
          const numSpecifiers = (
            (typeof log.message === 'function' &&
              log.message.match(formatSpecifierRegex)) ||
            []
          ).length
          numRestUsedForMsg = numSpecifiers
          return [...((log.rest || []).slice(0, numSpecifiers) || [])]
        }

        default:
          return (log.meta || {})[v] || log[v]
      }
    })

    const valuesWithRest = [
      ...values,
      ...(log.rest || []).slice(numRestUsedForMsg)
    ]

    let formatStr = this.formatDetails.format

    // Handle `message` specially since it can contain format specifiers itself which won't work as a value to our
    // format string. We're going to actually put the message directly into the format string to solve this.
    const messageIdx = this.formatDetails.vars.indexOf('message')

    if (messageIdx >= 0) {
      // Replace the messageIdx'th format specifier with the actual message.
      let nth = 0
      formatStr = formatStr.replace(formatSpecifierRegex, match => {
        return nth++ === messageIdx ? log.message : match
      })
    }

    return {
      message: formatStr,
      values: valuesWithRest
    }
  }
}
