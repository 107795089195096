import TagManager from 'react-gtm-module'
import { configureLogger } from './src/utils/logger'
import utmCookie from './src/utils/utm_cookie'
import { initVisitorId } from './src/utils/analytics-tracking'

// This contains client-side initialization that needs to happen before any other code we write is run. This is
// NOT run by gatsby directly, but rather via a plugin at the top of `gatsby-config.js` to assure it loads first.

const branch = process.env.GATSBY_BRANCH

function doInitialization() {
  configureLogger()

  // Configure and initialize Google Tag Manager
  const tagManagerArgs = {
    gtmId: process.env.GATSBY_GTM_ID,
    auth: process.env.GATSBY_GTM_AUTH,
    preview: process.env.GATSBY_GTM_PREVIEW,
    ...(branch && {
      dataLayer: {
        branch
      }
    })
  }

  // noinspection JSUnresolvedFunction
  TagManager.initialize(tagManagerArgs)

  function configureSentryScope() {
    if (typeof window !== `undefined` && window.Sentry && branch) {
      // noinspection JSUnresolvedFunction
      window.Sentry.configureScope((scope) => {
        scope.setTag('branch', branch)
      })
    }
  }

  configureSentryScope()

  // Invoke our in-house UTM cookie tracking
  utmCookie()
  initVisitorId()

}

// noinspection ES6ConvertVarToLetConst
var runEarlyInitializationOnce
const earlyInitializationOnce = () => {
  if (!runEarlyInitializationOnce) {
    runEarlyInitializationOnce = true

    doInitialization()
  }
}

// NOTE: We're actually running this NOW so the import triggers running since the gatsby browser hooks happen too late.
earlyInitializationOnce()

export default earlyInitializationOnce
