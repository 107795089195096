import { useEffect, useMemo, useState } from 'react'
import { useLocalizationContext } from '../../contexts/Localization/localizationProvider'
import { Locale } from '../../contexts/Localization/locale.enum'
import { cloneDeep } from 'lodash'

function createObjectStructure<T>(interfaceType: T): T {
  const obj: Partial<T> = {}
  for (const key in interfaceType) {
    if (Object.prototype.hasOwnProperty.call(interfaceType, key)) {
      obj[key] = undefined
    }
  }
  return obj as T
}

export function useLocalizedContent<T, E = {}>(
  data: any
): LocalizedContent<T, E> {
  const [content, setContent] = useState<T | null>(data)
  const { locale = Locale.en } = useLocalizationContext()

  useEffect(() => {
    const keys = Object.keys(data)
    const _data = cloneDeep(data)

    for (const key of keys) {
      if (!_data[key].nodes) continue
      _data[key].nodes = data[key].nodes?.filter(
        (node: any) => node.node_locale === locale
      )
    }
    setContent(_data)
  }, [data, locale])

  const translations = useMemo(() => {
    const _content = content as ContentGroup

    return (
      _content.allContentfulContentGroup?.nodes?.[0]?.contentEntries?.reduce(
        (acc: any, curr: ContentEntry) => {
          acc[curr.key] = curr.value?.value || curr.richTextValue?.json
          return acc
        },
        createObjectStructure<E>({} as E) || {}
      ) || {}
    )
  }, [content])

  return { content, translations }
}

interface LocalizedContent<T, E> {
  content: T | null
  translations: E | { [key: string]: any }
}

interface ContentGroup {
  allContentfulContentGroup?: {
    nodes?: {
      contentEntries?: ContentEntry[]
    }[]
  }
}

interface ContentEntry {
  key: string
  value: {
    value: string
  }
  richTextValue: {
    json: any
  }
}
