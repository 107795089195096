import {
  isDevelopSite,
  isProductionSite
} from '../../common/utils/environmentUtils'
import { isMobile, isMobileSafari } from 'react-device-detect'

const {
  theme: { _screens }
} = require('../../tailwind.config')

export const screens = _screens

export const ParseConfiguration = (configuration) => {
  const config =
    configuration && configuration.internal
      ? JSON.parse(configuration.internal.content)
      : null
  return config || {}
}

export const getUserInitials = (firstName, lastName) => {
  if (!firstName) return
  return `${firstName.substring(0, lastName ? 1 : 2)}${
    lastName ? lastName.substring(0, 1) : ''
  }`.toUpperCase()
}

export const getUserStateLink = (state = {}, defaultValue = '/') => {
  switch (state?.type) {
    case 'inactive':
      return '/welcome-back'
    case 'enrolled':
      return '/mycare'
    case 'plan':
    case 'quiz':
      return `/quiz/${state.conditionSlug}/?resume=true`
    default:
      return defaultValue
  }
}

export const isWebview =
  typeof window !== `undefined` && window.sessionStorage.getItem('webview')

export const assessmentPromptSlug =
  typeof window !== `undefined` && window.sessionStorage.getItem('promptSlug')

export const hasCoach = (allCoaches, user, type) => {
  if (!allCoaches) {
    return false
  }
  const { coaches = [] } = user
  if (coaches.length === 0) {
    return false
  }
  const coach = coaches.find((c) => c.type === type)
  if (
    coach &&
    allCoaches.some((c) => c.preferredName === coach.preferredName)
  ) {
    return true
  }
  return false
}

export const getUserCoachFromType = (user, type = 'program') => {
  const { coaches = [] } = user
  if (coaches.length === 0) {
    return user.coach || ''
  }
  const coach = coaches.find((c) => c.type === type)
  if (!coach) {
    return ''
  }
  return coach.preferredName
}

export const deepLinkTo = (path) => {
  const prodAppScheme = 'goodpath:///'
  const devAppScheme = 'goodpath-dev:///'
  const localAppScheme = 'exp://127.0.0.1:19000/--/'
  // For the goodpath-local app if you want the deep linking to open that instead of expo go.
  // export const localAppScheme = 'goodpath-local://'

  const redirectUrl = `${
    isProductionSite
      ? prodAppScheme
      : isDevelopSite
      ? devAppScheme
      : localAppScheme
  }${path}`

  if (window) {
    if (isMobile && !isMobileSafari) {
      try {
        // check if we have the app installed first
        window.location.href = redirectUrl
      } catch (e) {
        console.log(e)
      }
      return
    }

    const timeoutId = setTimeout(() => {}, 1000)
    return () => clearTimeout(timeoutId)
  }
}
