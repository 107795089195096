import { shouldLog } from './Helpers'
import { lowestPriorityLevel } from './Constants'

const assert = require('assert')

/**
 * Base class for a transport that synchronously logs to its destination service.
 */
export class SynchronousTransport {
  /**
   * Constructor
   *
   * @param level - The level required at minimum for a message to be sent by the transport. The message level must be
   * equal to or higher priority than this level to be logged by the transport. Default is lowestPriorityLevel.
   * @param meta - Additional metadata (an object) to add to all log objects sent.
   */
  constructor({ level = lowestPriorityLevel, meta } = {}) {
    this.level = level
    this.meta = meta
  }

  /**
   * Logs a message to the logger at the given log level.
   *
   * @param timestamp - The time the message was logged
   * @param level - The level to log the message at
   * @param name - The name of the logger
   * @param message - The message to log
   * @param loggerMeta - Metadata from the logger
   * @param rest - The rest of the objects to add to the log message. This will be treated differently depending on the
   * transport. E.g., for the console they're only used if added to the format string. For Loggly they're just added as
   * additional meta.
   */
  log(timestamp, level, name, message, loggerMeta, ...rest) {
    if (shouldLog({ logLevel: level, targetLevel: this.level })) {
      const log = {
        timestamp,
        level,
        name,
        message,
        meta: {
          ...loggerMeta,
          ...this.meta
        },
        rest
      }
      this.send({ logs: [log] })
    }
  }

  /**
   * Synchronously sends the given logs to the transport at the given log level.
   *
   * @param logs - An array of logs to send for the transport
   */
  send({ logs }) {
    assert(
      false,
      'ERROR: Derived transport class must override the send function.'
    )
  }
}
