import React, { memo } from 'react'
import HeaderNav from '../components/Header/HeaderNav'
import Footer from '../components/Layout/Footer'

export default memo(function DefaultLayout({ children, location }) {
  const page = location.pathname.substr(1)
  const showFooter = page !== 'get-started'
  const siteConf = process.env.GATSBY_CONTENTFUL_SITE_CONFIG || 'production'
  return (
    <div>
      <HeaderNav siteConf={siteConf} page={page} />
      <div>
        <main id="main">{children}</main>
      </div>
      {showFooter && <Footer siteConf={siteConf} />}
    </div>
  )
})
