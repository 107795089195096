import React from 'react'
import Button from './Button'
import { ChevronLeftIcon } from '../../utils/icons'

export default function BackButton({
  hasTitle = true,
  iconStyle,
  disabled = false,
  onClickOverwrite = null
}) {
  function onClick() {
    if (typeof window !== 'undefined') {
      window.history.back()
    }
  }

  return (
    <Button
      onClick={onClickOverwrite || onClick}
      variant="text"
      disabled={disabled}
      iconDirection="left"
      icon={<ChevronLeftIcon style={iconStyle} />}
    >
      {hasTitle && 'BACK'}
    </Button>
  )
}
