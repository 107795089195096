import React, { createContext, useCallback, useContext, useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useUserContext } from '../../contexts/User/userContext'
import { useLocalizedContent } from '../../utils/localization/useLocalizedContent'

const defaultState = {
  countries: [],
  getCountryByCode: () => undefined,
  currentCountry: undefined
} as ContextType

const CountryContext = createContext<ContextType>(defaultState)

const CountryProvider = ({ children }: Provider) => {
  const data: CountriesGqlData = useStaticQuery(graphql`
    query CountriesProviderQuery {
      allContentfulCountry {
        nodes {
          node_locale
          countryCode
          countryName
          emergencyTriageContent {
            json
          }
          countryPhoneCode
          phoneNumberMask
        }
      }
    }
  `)

  const { content: countriesData } = useLocalizedContent<CountriesGqlData>(data)
  const { user } = (useUserContext() as unknown) as { user: any }

  const countriesDict = useMemo<CountriesDictionary>(() => {
    return (
      countriesData?.allContentfulCountry.nodes?.reduce((acc, c) => {
        acc[c.countryCode.toLowerCase()] = {
          ...c,
          isNorthAmericanCountryCode: c.countryPhoneCode === 1
        }
        return acc
      }, {} as CountriesDictionary) || ({} as CountriesDictionary)
    )
  }, [countriesData?.allContentfulCountry.nodes])

  const countries = useMemo(() => Object.values(countriesDict), [countriesDict])
  const getCountryByCode = useCallback(
    (code: string = '') => {
      const [languageCode, countryCode] = code.toLowerCase().split('-')
      const _code = countryCode || languageCode
      return countriesDict[_code]
    },
    [countriesDict]
  )

  const currentCountry = useMemo(
    () => getCountryByCode(user?.address?.country),
    [getCountryByCode, user?.address?.country]
  )

  return (
    <CountryContext.Provider
      value={{
        countries,
        getCountryByCode,
        currentCountry
      }}
    >
      {children}
    </CountryContext.Provider>
  )
}

export default CountryProvider

export const useCountryContext = () => useContext(CountryContext)

export type ContextType = {
  countries: Country[]
  getCountryByCode: (code: string) => Country | undefined
  currentCountry: Country | undefined
}

export type Country = {
  countryName: string
  countryCode: string
  emergencyTriageContent?: {
    json: any
  }
  countryPhoneCode: number
  phoneNumberMask: string
  isNorthAmericanCountryCode: boolean
}

type CountriesDictionary = {
  [key: string]: Country
}

type CountriesGqlData = {
  allContentfulCountry: {
    nodes: Country[]
  }
}

type Provider = {
  initialState?: ContextType
  children: React.ReactNode
}
