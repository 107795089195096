import { v4 as uuidv4 } from 'uuid'

/**
 * UUID string (32 chars)
 *
 * Returns a string for a newly generated v4 UUID
 * with hyphens removed (for clickability) unless
 * includeDashes param is set to true
 */
export const uuid = (includeDashes = false) => {
  const uuidStr = uuidv4() + ''

  if (!includeDashes) {
    return uuidStr.replace(/-/g, '')
  }

  return uuidStr
}
