import EmptyLayout from './EmptyLayout'
import DefaultLayout from './DefaultLayout'
import SimpleLayout from './SimpleLayout'
import {
  DEFAULT_LAYOUT,
  EMPTY_LAYOUT,
  SIMPLE_LAYOUT
} from '../constants/layoutKeys'

const LayoutConstructor = {
  [EMPTY_LAYOUT]: EmptyLayout,
  [DEFAULT_LAYOUT]: DefaultLayout,
  [SIMPLE_LAYOUT]: SimpleLayout
}

export default LayoutConstructor
