import React, { useEffect } from 'react'
import { parse } from 'query-string'
import { useCookies } from 'react-cookie'
import LayoutConstructor from './LayoutConstructor'
import ChatWidgetSettings from '../components/Program/ChatWidgetSettings'
import 'react-awesome-popover/build/index.css'
import '../../src/styles/main.scss'
import { conditionSlugs } from 'goodpath-common'

function checkForGoodpather(cookie, setCookie, removeCookie, search) {
  let gpather = cookie.Goodpather && cookie.Goodpather === 'i_am_a_GOODpather'

  if (search && search.gp) {
    if (search.gp === 'true') {
      setCookie('Goodpather', 'i_am_a_GOODpather', {
        maxAge: 60 * 60 * 24 * 365 * 10,
        path: '/'
      })
      gpather = true
    } else {
      removeCookie('Goodpather')
      gpather = false
    }
  }

  return gpather ? (
    <div className="fixed top-0 right-0 z-50 p-1 bg-gray-100 opacity-100">
      Goodpather
    </div>
  ) : null
}
/**
 * This is far from perfect but a quick way to test out a proof of concept
 * for how we could slice metrics by a condition dimension in the free version if GA
 */
function checkCondition(setCookie, location) {
  conditionSlugs.forEach((cond) => {
    if (location?.pathname?.indexOf(cond) >= 0) {
      setCookie('gp_cond', cond, {
        maxAge: 60 * 60 * 24 * 365 * 10,
        path: '/'
      })
    }
  })
}

/**
 *
 */
const DefaultLayout = ({ children, pageContext, location }) => {
  const [cookie, setCookie, removeCookie] = useCookies([
    'Goodpather', // Indicates that client is Goodpath internal traffic
    'gp_cond', // Indicates the primary condition the site visit is attributed to,
  ])

  const search = location.search ? parse(location.search) : {}
  const goodpather = checkForGoodpather(cookie, setCookie, removeCookie, search)
  const Layout = LayoutConstructor[pageContext.layout || 'DefaultLayout']
  const withHeader =
    pageContext.withHeader !== undefined ? pageContext.withHeader : true

  useEffect(() => {
    if (location.pathnane && !cookie?.gp_cond) {
      checkCondition(setCookie, location)
    }
  }, [cookie, location, setCookie])

  // This is where we set the layout for every page
  // You need to add the layout property on gatsby-node, if not set defaults to "DefaultLayout"
  return (
    <>
      <Layout location={location} withHeader={withHeader}>
        {children}
      </Layout>
      <ChatWidgetSettings location={location} />
      {goodpather}
    </>
  )
}

export default DefaultLayout
