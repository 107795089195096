import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { Locale } from './locale.enum'
import { useLocation, Location } from '@reach/router'
import { addHeader } from '../../api/dashboard.api'
import useLocalStorage from '../../utils/useLocalStorage'

export type LocalizationContextProps = {
  locale?: Locale
  setLocale: (locale: Locale) => void
}

const defaultState: LocalizationContextProps = {
  locale: Locale.en,
  setLocale: () => {}
}

const LocalizationContext = createContext<LocalizationContextProps>(
  defaultState
)

const languageKey = 'lang'

const LocalizationProviderComponent = ({ children }: Props) => {
  const [locale, setLocale] = useState<Locale>()
  const [localeStorageValue, setLocalStorageValue] = useLocalStorage(
    languageKey
  )

  const location = useLocation()

  // Update locale and invoke callback
  const setLocaleHandler = useCallback(
    (_locale: Locale) => {
      if (!_locale || _locale === locale) return
      if (!Object.values(Locale).includes(_locale)) {
        return
      }
      setLocale(_locale)
      setLocalStorageValue(_locale)
      addHeader('x-gp-lang', _locale)
    },
    [setLocalStorageValue, locale]
  )

  useEffect(() => {
    if (localeStorageValue && localeStorageValue !== locale) {
      setLocaleHandler(localeStorageValue as Locale)
    } else if (!locale) {
      setLocaleHandler(Locale.en)
    }
  }, [locale, localeStorageValue, setLocaleHandler])

  useEffect(() => {
    const params = new URL(location.href).searchParams
    const languageParam = params.get(languageKey)

    if (!Object.values(Locale).includes(languageParam as Locale)) {
      return
    }

    if (languageParam && locale !== languageParam) {
      setLocaleHandler(languageParam as Locale)
    }
  }, [locale, location.href, setLocaleHandler])

  const value = useMemo(() => {
    return {
      locale: locale,
      setLocale: setLocaleHandler
    }
  }, [locale, setLocaleHandler])

  return (
    <LocalizationContext.Provider value={value}>
      {children}
    </LocalizationContext.Provider>
  )
}

export const LocalizationProvider = (props: Props) => {
  return (
    <Location>{() => <LocalizationProviderComponent {...props} />}</Location>
  )
}

export const useLocalizationContext = () => useContext(LocalizationContext)

type Props = {
  children: React.ReactNode
  locale?: Locale
  setLocale?: (locale: Locale) => void
}
