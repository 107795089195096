import React from 'react'
import classNames from 'classnames'
import Spinner from './Spinner'
import PropTypes from 'prop-types'

function Button({
  variant = 'primary',
  size,
  type,
  icon,
  onClick,
  disabled,
  ariaLabel,
  isLoading,
  isBlock,
  isBlockMobile,
  children,
  iconDirection = 'right',
  dataTestId = null,
  ...props
}) {
  const attributes = {
    ...(ariaLabel && { 'aria-label': ariaLabel }),
    ...(onClick && { onClick }),
    ...(disabled && { disabled }),
    ...Object.entries(props)
      .filter(([key]) => key.match(/data-.+/))
      .reduce((acc, entry) => ({ [entry[0]]: entry[1], ...acc }), {})
  }

  return (
    <button
      data-testid={dataTestId}
      className={classNames(
        `
          btn
          btn--${variant}
        `,
        {
          relative: isLoading,
          'btn--block': isBlock,
          [`btn--${size}`]: size,
          'btn--blockMobile': isBlockMobile
        }
      )}
      type={type}
      {...attributes}
    >
      {isLoading && (
        <div className="absolute left-0 w-10 h-full pt-2 pl-2">
          <Spinner />
        </div>
      )}
      {iconDirection === 'right' && children}
      {icon && !isLoading && <div>{icon}</div>}
      {iconDirection === 'left' && children}
    </button>
  )
}

Button.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'text',
    'secondary',
    'danger',
    'success',
    'brand',
    'white',
    'quiz'
  ]),
  size: PropTypes.oneOf(['large', 'small']),
  icon: PropTypes.element,
  iconDirection: PropTypes.oneOf(['left', 'right']),
  onClick: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  isBlock: PropTypes.bool,
  isBlockMobile: PropTypes.bool
}

export default Button
