import { levelPriorities } from './Constants'

const assert = require('assert')

/**
 * Returns true if logLevel is at the same or higher priority level than targetLevel.
 *
 * @param logLevel - The level of the log
 * @param targetLevel - The level of the target logger (or transport)
 *
 * @returns {boolean}
 */
export function shouldLog({ logLevel, targetLevel }) {
  assert(
    levelPriorities[logLevel],
    `[Logger] logLevel=${logLevel} is not a valid level from levelPriorities`
  )
  assert(
    levelPriorities[targetLevel],
    `[Logger] targetLevel=${targetLevel} is not a valid level from levelPriorities`
  )
  return levelPriorities[logLevel] <= levelPriorities[targetLevel]
}

export function getErrorMessage(error) {
  if (error instanceof Error) return error.message
  return String(error)
}
