/**
 * The priority level for each log level. These will determine if a log is sent to a transport or not. The level of the
 * log message itself my be >= the level of the transport's log level for the transport to record the message.
 *
 * NOTE: You can change the numbers in here however you want, but if you add or remove any of these you should make sure
 * your code isn't using any of the removed levels. :)
 */
export const levelPriorities = {
  error: 1,
  warn: 2,
  info: 3,
  verbose: 4,
  debug: 5
}

// The levels strings as properties keyed on the actual string. E.g., { error: 'error', warn: 'warn', ... }
export const levels = Object.keys(levelPriorities).reduce(
  (levels, level) => ({ ...levels, [level]: level }),
  {}
)

// The highest priority level (e.g., error)
export const highestPriorityLevel = Object.entries(levelPriorities).reduce(
  (highestLevelAndPriority, levelAndPriority) => {
    const [, highestPriority] = highestLevelAndPriority
    const [, priority] = levelAndPriority
    return priority <= highestPriority
      ? levelAndPriority
      : highestLevelAndPriority
  },
  ['', Number.MAX_SAFE_INTEGER]
)[0]

// The lowest priority level (e.g., debug)
export const lowestPriorityLevel = Object.entries(levelPriorities).reduce(
  (lowestLevelAndPriority, levelAndPriority) => {
    const [, lowestPriority] = lowestLevelAndPriority
    const [, priority] = levelAndPriority
    return priority >= lowestPriority
      ? levelAndPriority
      : lowestLevelAndPriority
  },
  ['', -Number.MAX_SAFE_INTEGER]
)[0]

// A logger you can use that does nothing.
export const NoOpLogger = Object.keys(levelPriorities).reduce(
  (logger, levelName) => ({ ...logger, [levelName]: () => {} }),
  {
    child: function () {
      return this
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    log: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    flush: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    updateMeta: () => {}
  }
)

// A logger that logs to the console.
export const ConsoleLogger = Object.keys(levelPriorities).reduce(
  (logger, levelName) => ({
    ...logger,
    [levelName]: (...argsArr) =>
      console[levelName]
        ? console[levelName](...argsArr)
        : console.info(...argsArr)
  }),
  {}
)
