const isDevelopment = process.env.NODE_ENV === 'development'
const isProductionSite = process.env.GATSBY_IS_PROD === 'true'
const isDevelopSite = process.env.GATSBY_IS_DEV === 'true'

const getInstanceUrl = () => {
  const { GATSBY_URL } = process.env
  return GATSBY_URL && GATSBY_URL !== `undefined`
    ? GATSBY_URL
    : 'http://localhost:8000'
}

module.exports = {
  isDevelopment,
  isProductionSite,
  isDevelopSite,
  getInstanceUrl,
}
