// User related
export const USER = 'user'

// Quiz / Assessments
export const QUIZ_STATE = 'quizState'
export const GOODPATH_SCORE = 'gp_score'

// Plan Related
export const PLAN_ID = 'planId'
export const PROGRAM_ID = 'programId'
export const CONDITION_SLUG = 'planConditionSlug'
export const CONDITION_NAME = 'planConditionName'
export const PLAN_PRICE = 'planPrice'
export const SELECTED_PLAN = 'selectedPlan'
export const VIEWED_PLAN = 'viewedPlan'

export const currentActiveQuizKey = 'current-active-quiz'