import { memo } from 'react'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

let logRocketInitialized = false

/**
 * Initializes LogRocket
 */
const setupLogRocket = () => {
  console.log(`setupLogRocket logRocketInitialized=${logRocketInitialized}`)
  if (!logRocketInitialized) {
    console.log('setupLogRocket initializing LogRocket')
    const logRocketAppId = process.env.GATSBY_LOG_ROCKET_APPID
    const logRocketRelease = process.env.GATSBY_LOG_ROCKET_RELEASE

    if (logRocketAppId) {
      LogRocket.init(logRocketAppId, {
        rootHostname: 'goodpath.com',
        ...(logRocketRelease && { release: logRocketRelease })
        // TODO: Disabling to test if it provides more info to LR. Can remove entirely if this doesn't cause any problems.
        // console: {
        //   isEnabled: false
        // }
      })

      setupLogRocketReact(LogRocket)

      if (typeof window !== `undefined` && window.Sentry) {
        LogRocket.getSessionURL((sessionURL) => {
          // eslint-disable-next-line no-undef
          // noinspection JSUnresolvedFunction
          window.Sentry.configureScope((scope) => {
            scope.setExtra('sessionURL', sessionURL)
          })
        })
      }
    }

    logRocketInitialized = true
  }
}

/**
 * Include this component on any pages that you want to initiate LogRocket
 * session recording. We do not want to record every single session on our site
 * since at this time (Aug 2021) the vast majority of public website sessions
 * are from organic Google searches into our various inbound SEO content pages
 * (articles and landing pages), where people read the page and do not sign up
 * or log in to an account. This eats up our session quota limits on our
 * LogRocket plan with mostly unvaluable session recordings that are just staring
 * at an article page with nothing happening.
 */
const LogRocketComponent = memo(() => {
  setupLogRocket()

  return null
})

export { LogRocketComponent }
