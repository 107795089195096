import { useEffect } from 'react'

export function toggleNav(setNavVisible, navVisible) {
  setNavVisible(!navVisible)
}

export function useOutsideClick(ref, navVisible, onClickOutside = () => {}) {
  // clicked on outside of element
  function handleClickOutside(event) {
    if (navVisible && ref.current && !ref.current.contains(event.target)) {
      onClickOutside(true)
      return
    }
    onClickOutside(false)
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })
}

export function getRetakeUrl(conditionSlug) {
  if (!conditionSlug) {
    return `/invalid`
  } else {
    return `/signup`
  }
}
