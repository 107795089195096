import { extend } from 'lodash'

// The console functions that will be redirected.
export const redirectedConsoleFuncs = ['log', 'error', 'info', 'debug', 'warn']

export const redirectMapping = {
  ...redirectedConsoleFuncs.reduce(
    (mapping, func) => ({ ...mapping, [func]: func }),
    {}
  ),

  // This is the only one not accounted for in levelPriorities...
  trace: 'debug'
}

const clonedOriginalConsole = {}
extend(clonedOriginalConsole, console)

/**
 * Always returns the original non-redirected console whether redirected or not.
 */
export function originalConsole() {
  return clonedOriginalConsole
}

/**
 * This redirects the global console log to the given logger.
 *
 * @param logger - The logger to redirect the console to.
 */
export function redirectConsole({ logger }) {
  // Overwrite implementations of the log functions in the real console var to use our logger.
  redirectedConsoleFuncs
    // Leave out `log` since we have to define that specially below
    .filter(l => l !== 'log')
    .forEach(level => {
      if (console[level]) {
        console[level] = function() {
          const args = Array.prototype.slice.call(arguments)
          logger[redirectMapping[level]].apply(logger, args)
        }
      }
    })

  // Log function we handle a little differently because our log takes the level as the first parameter.
  // Use 'info' level when we use console.log directly.
  console.log = function(level) {
    const args = Array.prototype.slice.call(arguments)
    args.unshift('info')
    logger.log.apply(logger, args)
  }
}

/**
 * This restores the global console log to point to the original console log if redirected.
 */
export function restoreConsole() {
  redirectedConsoleFuncs.forEach(level => {
    console[level] = clonedOriginalConsole[level]
  })
}
