import axios from 'axios'
import { createApi } from 'goodpath-common'
import cuid from 'cuid'

export const oldApiClient = axios.create({
  baseURL: `/netlify/api`
})

export const newApiClient = axios.create({
  baseURL: `/api`
})

export const addRequestIdMiddleware = async (request) => {
  const requestId = cuid.slug() // Use a short ID for easier searching / copy-pasting. Unique enough.
  request.headers = {
    ...request.headers,
    'x-request-id': requestId
  }

  return request
}

oldApiClient.interceptors.request.use(addRequestIdMiddleware)
newApiClient.interceptors.request.use(addRequestIdMiddleware)

const webApi = createApi(oldApiClient, newApiClient)

export const addHeader = (key, value) => {
  oldApiClient.defaults.headers.common[key] = value
  newApiClient.defaults.headers.common[key] = value
}

export default webApi
