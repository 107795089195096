import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { sprintf } from 'sprintf-js'
import ContentLink from '../ContentLink'

import Brandmark from '../../../assets/brandmark.svg'
import { links } from '../../utils/useAllowedConditions'
import ConditionProvider from '../common/ConditionProvider'

const h3Style =
  'mb-2 text-base md:text-sm uppercase text-gp-violet font-bold tracking-wider'
const linkStyle =
  'inline-block text-lg md:text-base text-gp-grey-70 hover:text-gp-grey-90 focus:text-gp-grey-90 font-normal py-3 pr-3 md:py-0 md:pr-3'

const FooterComponent = ({ siteConf }) => {
  const { content } = useStaticQuery(query)
  const pageData = content.nodes.filter((h) => {
    return h.environmentName === siteConf
  })[0].globalFooter
  const currentYear =
    process.env.GATSBY_CURRENT_YEAR || new Date().getFullYear()

  const conditions = links

  return (
    <footer
      className="bg-grey-lightest border-grey-lightest"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="md:grid md:grid-cols-12 md:gap-4">
          <div className="space-y-8 md:col-span-3">
            <Link
              className="inline-block hover:opacity-75 focus:opacity-75"
              to="/"
            >
              <Brandmark width="44" height="69" className="cursor-pointer" />
            </Link>
            <span
              dangerouslySetInnerHTML={{
                __html: sprintf(pageData.address, '<br />')
              }}
              className="block text-base font-bold leading-normal md:text-base text-gp-grey-90"
            />
          </div>

          <div className="grid mt-12 md:mt-0 md:col-span-9">
            <div className="grid gap-8 lg:gap-16 md:grid-cols-3">
              <div className="grid content-start">
                <h3 className={h3Style}>Goodpath</h3>
                <ul className="pl-0 list-none">
                  {pageData.internalLinks.map((link) => (
                    <li key={link.url} className="list-none">
                      <ContentLink link={link} className={linkStyle} />
                    </li>
                  ))}
                </ul>
              </div>

              <div className="grid content-start">
                <h3 className={h3Style}>Custom Programs</h3>
                <ul className="pl-0 list-none">
                  {conditions.map((linkInfo) => (
                    <li key={linkInfo.url} className="list-none">
                      <ContentLink link={linkInfo} className={linkStyle} />
                    </li>
                  ))}
                </ul>
              </div>

              <div className="grid content-start">
                <h3 className={h3Style}>Who We Are</h3>
                <ul className="pl-0 list-none">
                  {pageData.whoWeAre.map((link) => (
                    <li key={link.url} className="list-none">
                      <ContentLink link={link} className={linkStyle} />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8 mt-12 border-t border-gp-grey-30">
          <span className="text-sm">
            &copy;{`${currentYear} ${pageData.copyright}`}
          </span>
          <ul className="flex flex-col pl-0 mt-6 space-y-4 list-none md:mt-6 md:flex-row md:space-x-4 md:space-y-0">
            {pageData.legalDocumentLinks.map((link) => (
              <li key={link.url} className="mt-0 list-none">
                <ContentLink
                  link={link}
                  className="inline-block py-3 pr-3 text-base no-underline md:text-sm md:p-0 text-gp-grey-60 hover:underline hover:text-gp-grey-80 focus:text-gp-grey-80"
                />
              </li>
            ))}
          </ul>
        </div>
        {pageData.liabilityNote && (
          <div className="w-full p-4 m-auto mt-4 text-sm leading-snug bg-white border lg:max-w-5xl border-gp-grey-30 lg md:mt-8">
            {pageData.liabilityNote}
          </div>
        )}
      </div>
    </footer>
  )
}

const Footer = ({ siteConf }) => (
  <ConditionProvider>
    <FooterComponent siteConf={siteConf} />
  </ConditionProvider>
)

export default Footer

export const query = graphql`
  query {
    content: allContentfulSiteConfig(filter: {node_locale: {eq: "en-US"}}) {
      nodes {
        environmentName
        globalFooter {
          id
          address
          copyright
          legalDocumentLinks {
            isInternal
            isDisabled
            title
            url
          }
          internalLinks {
            isInternal
            isDisabled
            title
            url
          }
          services {
            isInternal
            isDisabled
            title
            url
          }
          whoWeAre {
            isInternal
            isDisabled
            title
            url
          }
          liabilityNote
        }
      }
    }
  }
`
