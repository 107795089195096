const isDoNotTrackEnabled = () => {
  if (typeof window === 'undefined') return false
  // Safari 7.1.3+ uses window.doNotTrack rather than navigator.doNotTrack.
  // IE9+ Implemented with the vendor prefix: ms
  // For IE11 and subsequent versions, use window.doNotTrack
  const { doNotTrack, navigator } = window
  const dnt = doNotTrack || navigator.doNotTrack || navigator.msDoNotTrack
  return (
    dnt === true ||
    dnt === 1 ||
    dnt === 'yes' ||
    (typeof dnt === 'string' && dnt.charAt(0) === '1')
  )
}

export default function () {
  if (isDoNotTrackEnabled()) {
    console.log('utm_cookie Do not track is on')
    return
  }

  const settings = {
    cookieNameFirstTouchPrefix: 'ft_',
    cookieNamePrefix: 'lt_',
    utmParams: [
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_term',
      'utm_content'
    ],
    cookieExpiryDays: 365,
    isFirstTouch: null
  }

  const utils = {
    topDomain: function () {
      const top_level_cookie = 'top_level_domain=cookie'
      const hostname = window.document.location.hostname.split('.')
      for (let i = hostname.length - 1; i >= 0; i--) {
        const h = hostname.slice(i).join('.')
        window.document.cookie = top_level_cookie + ';domain=.' + h + ';'
        if (window.document.cookie.indexOf(top_level_cookie) > -1) {
          window.document.cookie =
            top_level_cookie.split('=')[0] +
            '=;domain=.' +
            h +
            ';expires=Thu, 01 Jan 1970 00:00:01 GMT;'
          return h
        }
      }
      return window.document.location.hostname
    },
    isFirstTouch: function () {
      if (settings.isFirstTouch != null) return settings.isFirstTouch
      else {
        const f =
          window.document.cookie.indexOf(
            settings.cookieNameFirstTouchPrefix
          ) === -1
        settings.isFirstTouch = f
        return f
      }
    },
    isNewSession: function () {
      const c = '__utm_tracking_session'
      const r = cookies.read(c) === undefined
      cookies.create(c, true, 1 / 48) // session window is half-hour
      return r
    }
  }

  const cookies = {
    create: function (name, value, days) {
      let expires = ''
      if (days) {
        const date = new Date()
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
        expires = '; expires=' + date.toGMTString()
      }

      let domain = utils.topDomain()

      // Add the dot before the top level domain unless this is localhost dev
      if (domain !== 'localhost') domain = `.${domain}`

      const c = `${name}=${value}${expires}; domain=${domain}; path=/`
      console.log(`utm_cookie set ${c}`)
      window.document.cookie = c
    },
    writeCookieOnce: function (name, value) {
      if (utils.isFirstTouch()) {
        this.create(
          settings.cookieNameFirstTouchPrefix + name,
          value,
          settings.cookieExpiryDays
        )
      }
      this.create(
        settings.cookieNamePrefix + name,
        value,
        settings.cookieExpiryDays
      )
    },
    read: function (name) {
      const nameEQ = name + '='
      const ca = window.document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
      }
    },
    erase: function (name) {
      this.createCookie(name, '', -1)
    }
  }

  const base = {
    getParameterByName: function (name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
      const regexS = '[\\?&]' + name + '=([^&#]*)'
      const regex = new RegExp(regexS)
      const results = regex.exec(window.location.search)
      if (results == null) {
        return ''
      } else {
        return decodeURIComponent(results[1].replace(/\+/g, ' '))
      }
    },
    utmPresentInUrl: function () {
      for (let i = 0; i < settings.utmParams.length; i++) {
        const param = settings.utmParams[i]
        const value = this.getParameterByName(param)
        if (value !== '' && value !== undefined) {
          return true
        }
      }
      return false
    },
    writeUtmCookieFromParams: function () {
      if (this.utmPresentInUrl()) {
        for (let i = 0; i < settings.utmParams.length; i++) {
          const param = settings.utmParams[i]
          const value = this.getParameterByName(param)
          cookies.writeCookieOnce(param, value)
        }
      }
    },
    writeReferrer: function () {
      const value = window.document.referrer
      const key = 'referrer'
      if (
        value &&
        value !== '' &&
        value !== undefined &&
        value.indexOf(window.document.location.host) === -1
      ) {
        cookies.writeCookieOnce(key, value)
      } else {
        cookies.writeCookieOnce(key, 'direct')
      }
    },
    storeParamsInCookies: function () {
      if (utils.isNewSession()) {
        this.writeUtmCookieFromParams()
        this.writeReferrer()
      }
    }
  }

  const form = {
    insertToForm: function () {
      this.insertToFormEachField(settings.cookieNameFirstTouchPrefix)
      this.insertToFormEachField(settings.cookieNamePrefix)
    },
    insertToFormEachField: function (type) {
      const fields = settings.utmParams
      fields.push('referrer')

      for (let f = 0; f < fields.length; f++) {
        const field = type + fields[f]

        const val = cookies.read(field)

        const elms = window.document.querySelectorAll(
          "input[name='" + field + "']"
        )

        if (val !== undefined && elms.length > 0) {
          for (let e = 0; e < elms.length; e++) {
            elms[e].value = val
          }
        }
      }
    }
  }

  base.storeParamsInCookies()

  window.addEventListener('message', function (event) {
    if (
      event.data.type === 'hsFormCallback' &&
      event.data.eventName === 'onFormReady'
    ) {
      console.log('utm_cookie Form Is Ready!')
      form.insertToForm()
    }
  })
}
