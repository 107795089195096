import { logErr } from './logger'
import LogRocket from 'logrocket'
import { getCookie, setCookie } from './cookie'
import { uuid } from '../../common/utils/id_generator'
import slugify from 'slugify'
import {
  getWeekOfYear,
  getDayOfYear,
  conditionShortCodes
} from 'goodpath-common'

const checkGoodpather = (email) => {
  if (email) {
    return email.endsWith('@goodpath.com')
  } else {
    return getCookie('Goodpather') === 'i_am_a_GOODpather'
  }
}

export const PushToDatalayer = (event) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(event)
}

export const gaTrack = ({ category, action, label, value }) => {
  PushToDatalayer({
    event: 'gtm.generic-event',
    data: {
      category,
      action,
      label,
      value
    }
  })
}

/**
 * Identify session user in analytics platforms
 */
export const setUserAttributes = (user = {}) => {
  if (typeof window !== `undefined`) {
    console.log('setUserAttributes user', user)

    const { email, userId } = user
    if (!email && !userId) return

    // Reduced user info for 3rd parties
    const userInfo = {
      ...(user.userId && { userId: user.userId }),
      ...(user.email && { email: user.email }),
      ...(user.firstName && { firstName: user.firstName }),
      ...(user.lastName && { lastName: user.lastName }),
      ...(user.conditionSlug && { conditionSlug: user.conditionSlug }),
      ...(user.employerSlug && { employerSlug: user.employerSlug }),
      ...(user.planId && { planId: user.planId }),
      ...(user.createdAt && { createdAt: user.createdAt }),
      ...(user.userCreatedAt && { userCreatedAt: user.userCreatedAt }),
      ...(user.settings && { settings: user.settings }),
      ...(user.phoneNumber && {
        phoneNumber: user.phoneNumber,
        phone: user.phoneNumber
      })
    }

    // Segment
    updateSegmentProfile(userInfo)

    // Klaviyo
    updateKlaviyoProfile(userInfo)

    // LogRocket
    updateLogRocketProfile(userInfo)

    // Sentry
    updateSentryProfile(userInfo)

    // HotJar
    updateHotJarProfile(userInfo)

    // GTM / GA custom dimensions
    pushUserDimensions(userInfo)
  }
}

/**
 * Check if there is already a visitor id on this client, if not then set one.
 * NOTE: This is an artifact from our older B2C model where unauthenticated site
 * visitors could do the intake assessment without creating an account first.
 * We should be able to remove this visitor id in a future cleanup pass as we
 * no longer use it downstream in analytics anymore.
 */
export const initVisitorId = () => {
  let visitorId = getCookie('gp_v')
  if (!visitorId) {
    visitorId = uuid()
    setCookie('gp_v', visitorId, 10 * 365)
  }
}

/**
 * Tracks an event for a user action to all our analytics services.
 * The user identity attributed to the event will be what is in memory
 * from the most recent prior call to setUserAttributes
 *
 * @param eventName the name for the event to be tracked
 * @param eventData the properties of the event to be tracked
 *                  reserved property 'includeUnique' will generate
 *                  an additional one-time event to GTM
 */
export const trackUserAction = (eventName, eventData = {}) => {
  console.log(`trackUserAction ${eventName}`, eventData)
  const eventSlug = slugify(eventName)
  const { includeUnique } = eventData

  // Track event to Segment
  if (window.analytics) {
    window.analytics.track(eventName, { ...eventData })
  }

  // Track event to LogRocket
  LogRocket.track(eventName)

  // Track event to GTM
  PushToDatalayer({
    event: `gtm.${eventSlug}`,
    data: { ...eventData }
  })

  // If specified, track a special unique (one time per user) event to GTM
  if (includeUnique) {
    const uniqueEvent = `gtm.${eventSlug}-unique`
    doOnce(eventName, () => {
      PushToDatalayer({
        event: uniqueEvent,
        data: { ...eventData }
      })
      console.log(`Tracked unique one-time event ${uniqueEvent}`, eventData)
    })
  }
}

/**
 * Tracks a quiz step being reached.
 */
export const trackQuizStep = ({
  quizType,
  condition,
  quizSlug,
  assessmentId,
  stepId,
  flowStep
}) => {
  const eventData = {
    quizType,
    condition,
    ...(quizSlug && { quizSlug }),
    assessmentId,
    stepId,
    flowStep
  }

  trackUserAction('Quiz Step Viewed', eventData)
  console.log(`Tracked Quiz Step id=${stepId}`)
}

export const updateKlaviyoProfile = ({
  userId,
  email,
  firstName,
  lastName,
  phoneNumber,
  conditionSlug,
  employerSlug,
  planId,
  ...rest
}) => {
  try {
    const profileUpdate = {
      ...(userId && { $id: userId }),
      ...(email && { $email: email }),
      ...(firstName && { $first_name: firstName }),
      ...(lastName && { $last_name: lastName }),
      ...(phoneNumber && { $phone_number: phoneNumber }),
      ...(conditionSlug && { 'most-recent-condition': conditionSlug }),
      ...(employerSlug && { 'employer-slug': employerSlug }),
      ...(planId && {
        [`planId_${getConditionShortCode(conditionSlug)}`]: planId,
        'most-recent-plan': getConditionShortCode(conditionSlug)
      }),
      'profile-branch': process.env.GATSBY_BRANCH,
      ...rest
    }

    // Update Klaviyo profile
    const _learnq = window._learnq || []
    _learnq.push(['identify', profileUpdate])
    console.log(`Klaviyo identify`, profileUpdate)
  } catch (err) {
    logErr({
      err,
      msg: 'Error updating Klaviyo profile',
      extra: { userId, email, conditionSlug }
    })
  }
}

export const updateLogRocketProfile = ({
  userId,
  email,
  firstName,
  conditionSlug,
  employerSlug
}) => {
  if (userId) {
    const profileUpdate = {
      name: firstName,
      email,
      condition: conditionSlug,
      employer: employerSlug
    }
    LogRocket.identify(userId, profileUpdate)
    console.log(`LogRocket identify`, { userId, ...profileUpdate })
  }
}

const updateSentryProfile = ({ userId, email }) => {
  if (window.Sentry) {
    const profileUpdate = {
      id: userId,
      email
    }
    window.Sentry.configureScope((scope) => scope.setUser(profileUpdate))
    console.log(`Sentry setUser`, profileUpdate)
  }
}

const updateHotJarProfile = ({ userId, email, employerSlug }) => {
  if (window.hj) {
    const isGoodpather = checkGoodpather(email)
    const profileUpdate = {
      email,
      isGoodpather,
      ...(employerSlug && { employer: employerSlug })
    }

    window.hj('identify', userId, profileUpdate)
    console.log(`HotJar identify`, { userId, ...profileUpdate })
  }
}

const updateSegmentProfile = ({
  userId,
  email,
  conditionSlug,
  employerSlug
}) => {
  if (window.analytics) {
    const isGoodpather = checkGoodpather(email)
    const goodpather = getCookie('Goodpather')

    const profileUpdate = {
      email,
      condition: conditionSlug,
      employer: employerSlug,
      isGoodpather,
      ...(goodpather && { goodpather })
    }

    window.analytics.identify(userId, profileUpdate)

    console.log(`Segment identify`, { userId, ...profileUpdate })
  }
}

const pushUserDimensions = async (user) => {
  console.log(`GTM pushUserDimensions user`, user)
  if (!user) return

  const dateCreated = user.userCreatedAtDate || user.createdAt
  pushUserCreatedDateCohort(dateCreated)
  pushEmployerDimension(user.employerSlug)
}

const pushEmployerDimension = (employerSlug) => {
  if (!employerSlug) return

  PushToDatalayer({
    employerSlug
  })
  console.log(`GTM pushEmployerDimension employerSlug=${employerSlug}`)
}

const pushUserCreatedDateCohort = (value) => {
  if (!value) return

  try {
    let dateCreated
    if (value._seconds) {
      dateCreated = new Date(value._seconds * 1000)
    } else {
      dateCreated = new Date(value)
    }

    // NOTE: Commenting this out to disable this behavior, but keeping it here
    // so it's quick to put back in case we want it in again.
    // const now = new Date()
    // if (dateDiff(now, dateCreated, 'days') > 21) return

    const week = getWeekOfYear(dateCreated)
    const year = dateCreated.getFullYear().toString().substring(2)

    const day = getDayOfYear(dateCreated)
    const dateString = `${year}${('0' + (dateCreated.getMonth() + 1)).substr(
      -2
    )}${('0' + dateCreated.getDate()).substr(-2)}`

    PushToDatalayer({
      userCreatedAtCohortWeek: `${year}${week}`,
      userCreatedAtCohortDay: `${year}${day}`,
      userCreatedAtCohortDate: dateString
    })
    console.log(`GTM pushUserCreatedDateCohort dateString=${dateString}`)
  } catch (error) {
    logErr({ err: error, msg: 'Error in pushUserCreatedDateCohort function' })
  }
}

const getConditionShortCode = (conditionSlug) => {
  return conditionShortCodes[conditionSlug] || ''
}

const doOnce = (opName, opFunc) => {
  let onceOps = window.localStorage.getItem('onceOps')

  if (onceOps) {
    onceOps = JSON.parse(onceOps)
    console.log(`existing opName=${opName} onceOps,`, onceOps)
  } else {
    onceOps = {}
    console.log(`new onceOps,`, onceOps)
  }

  if (!onceOps[opName]) {
    opFunc()
    console.log(`setting onceOps opName ${opName} to ${new Date()}`)
    onceOps[opName] = new Date()
    window.localStorage.setItem('onceOps', JSON.stringify(onceOps))
  }
}
